import React from 'react';
import { Box, Typography, IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../styles/CustomModal.css';

const CustomModal = ({ open, handleClose, title, children, footer, sx }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      closeAfterTransition
      sx={{
        display: 'flex',
        alignItems: { xs: 'flex-end', md: 'center' },
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: { xs: '100%', md: 400 },
          height: { xs: '100%', md: 'auto' },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          transform: open ? 'translateY(0)' : 'translateY(100%)',
          transition: 'transform 0.3s ease-out',
          ...sx,  // Combineer de meegegeven sx prop met de bestaande styling
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography id="modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ mt: 2, flexGrow: 1, overflow: 'auto', alignItems: 'flex-start' }}>
          {children}
        </Box>
        <Box sx={{ mt: 2 }}>
          {footer}
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomModal;
