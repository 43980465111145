import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';

const ProductSignup = ({ salesItem, location }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    // Hier kunt u de gegevens verwerken, bijvoorbeeld door ze naar een server te sturen
    console.log('Naam:', name);
    console.log('Telefoonnummer:', phone);
    console.log('E-mailadres:', email);
    // Reset het formulier
    setName('');
    setPhone('');
    setEmail('');
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6">Niet beschikbaar</Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        De {salesItem.label} unit is momenteel niet beschikbaar in {location.description}. Laat uw gegevens achter om op de hoogte te worden gehouden.
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Naam"
          variant="outlined"
          fullWidth
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Telefoonnummer"
          variant="outlined"
          fullWidth
          required
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          label="E-mailadres"
          variant="outlined"
          fullWidth
          required
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button type="submit" variant="contained" color="primary">
          Hou me op de hoogte
        </Button>
      </form>
    </Box>
  );
};

export default ProductSignup;
