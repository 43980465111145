import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Box, Button, Typography, CircularProgress, Container, Card, CardContent, Link, Stepper, Step, StepLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import api from '../../api';
import SimpleHeader from '../SimpleHeader';
import SimpleFooter from '../SimpleFooter';
import { useUserDetails } from '../../context/UserDetail';
import { useBooking } from '../../context/booking/BookingProvider';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import PaymentIcon from '@mui/icons-material/Payment';
import { useTheme } from '@mui/material/styles';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Voer een geldig e-mailadres in')
    .required('E-mailadres is verplicht'),
  authCode: yup
    .string()
    .when('userExists', {
      is: true,
      then: (schema) => schema.required('Authenticatiecode is verplicht'),
      otherwise: (schema) => schema.nullable(),
    }),
  userExists: yup.boolean().default(false),
});

const steps = [
  { label: 'Extra\'s', icon: <CheckCircleIcon /> },
  { label: 'Gegevens', icon: <InfoIcon /> },
  { label: 'Betaling', icon: <PaymentIcon /> }
];

const StepIconComponent = ({ icon, active, completed }) => {
  const theme = useTheme(); // Haal het huidige thema op
  
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', color: completed ? 'green' : active ? theme.palette.primary.main : 'gray' }}>
      {icon}
    </Box>
  );
};

const Login = () => {
  const navigate = useNavigate();
  const { updateUserDetails } = useUserDetails();
  const { bookingDetails } = useBooking();

  useEffect(() => {
    document.title = `Klantgegevens | Swartbox`;
    if (!bookingDetails.selectedLocation || !bookingDetails.selectedBox) {
      navigate('/');
    }
  }, [bookingDetails, navigate]);

  const { handleSubmit, control, formState: { errors, isSubmitting, isValid }, setValue, watch } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: {
      email: '',
      authCode: '',
      userExists: false,
    }
  });

  const userExists = watch('userExists');

  const handleGenerateAuthCode = async ({ email }) => {
    try {
      await api.post('/auth/generateAuthCode', { email }, { withCredentials: true });
      setValue('userExists', true);
      alert('Een authenticatiecode is naar uw e-mailadres gestuurd.');
    } catch (error) {
      if (error.response && error.response.status === 404) {
        navigate('/user/register');
      } else {
        console.error('Error generating auth code:', error);
        alert('Er is een fout opgetreden bij het genereren van de authenticatiecode.');
      }
    }
  };

  const handleVerifyAuthCode = async ({ email, authCode }) => {
    try {
      const response = await api.post('/auth/verifyAuthCode', { email, authCode }, { withCredentials: true });
      if (response.status === 200) {
        navigate('/user/profile');
      }
    } catch (error) {
      console.error('Error verifying auth code:', error);
      alert('Ongeldige authenticatiecode.');
    }
  };

  const onSubmit = async (data) => {
    updateUserDetails({ email: data.email });
    if (userExists) {
      await handleVerifyAuthCode(data);
    } else {
      await handleGenerateAuthCode(data);
    }
  };

  const activeStep = 1;  // Gegevens stap is actief
  const completedSteps = [0];  // Extra's stap is afgerond

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <SimpleHeader />
      <Container sx={{ flex: 1, mt: 4 }} component="main" maxWidth="md">
        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          {steps.map((step, index) => (
            <Step key={step.label} completed={completedSteps.includes(index)}>
              <StepLabel StepIconComponent={() => (
                <StepIconComponent
                  icon={step.icon}
                  active={activeStep === index}
                  completed={completedSteps.includes(index)}
                />
              )}>
                {step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Typography variant="h4" component="h1" sx={{ mb: { xs: 2, md: 4}, textAlign: { xs: 'left', md: 'center'} }}>
          Gegevens
        </Typography>
        <Card sx={{ border: { xs: 'none', md: '1px solid #ddd' }, mb: 2, boxShadow: { xs: 'none', md: 1 } }}>
          <CardContent>
            <Container maxWidth="xs">
              <Typography variant="h6" sx={{ mb: { xs: 2, md: 4} }}>
                Log in met je account of maak een nieuw account aan
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Begin hieronder met het invullen van je e-mailadres
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Email"
                      type="email"
                      fullWidth
                      sx={{
                        mb: 2,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: field.value && !errors.email ? '#4caf50' : '',
                          },
                        },
                      }}
                      error={!!errors.email}
                      helperText={errors.email ? errors.email.message : ''}
                    />
                  )}
                />
                {userExists && (
                  <Controller
                    name="authCode"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Authentication Code"
                        fullWidth
                        sx={{
                          mb: 2,
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: field.value && !errors.authCode ? '#4caf50' : '',
                            },
                          },
                        }}
                        error={!!errors.authCode}
                        helperText={errors.authCode ? errors.authCode.message : ''}
                      />
                    )}
                  />
                )}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!isValid || isSubmitting}
                    startIcon={isSubmitting && <CircularProgress size={24} />}
                  >
                    {isSubmitting ? '' : userExists ? 'Verify Code' : 'Verder gaan'}
                  </Button>
                  {userExists && (
                    <Button 
                      variant="outlined" 
                      color="secondary" 
                      onClick={() => handleGenerateAuthCode(watch())}
                      disabled={isSubmitting}
                    >
                      Resend Code
                    </Button>
                  )}
                </Box>
              </form>
            </Container>
          </CardContent>
        </Card>
        <Link href="#" onClick={() => navigate(-1)} sx={{ textAlign: 'left', display: 'block', mt: 2 }}>
          Naar vorige stap
        </Link>
      </Container>
      <SimpleFooter />
    </Box>
  );
};

export default Login;
