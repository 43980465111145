import React from 'react';
import { AppBar, Toolbar, Box, Typography, IconButton } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import { Link } from 'react-router-dom';

const SimpleHeader = () => {

  return (
    <AppBar position="static">
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{ textDecoration: 'none', color: 'inherit' }}
          >
            <img src="/logo.png" alt="Swartbox Logo" style={{ height: 20 }} />
          </Typography>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            alignItems: 'center',
            justifyContent: 'flex-end',
            flexGrow: 1,
          }}
        >
          <IconButton color="inherit">
            <PhoneIcon />
          </IconButton>
          <Typography variant="body1" noWrap>
            0107142579
          </Typography>
        </Box>
        <Box
          sx={{
            display: { xs: 'flex', md: 'none' },
            justifyContent: 'flex-end',
            flexGrow: 1,
          }}
        >
          <IconButton edge="start" color="inherit" aria-label="phone" component="a" href="tel:31107142579">
            <PhoneIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default SimpleHeader;
