import React, { createContext, useContext, useState, useCallback, useEffect, useMemo } from 'react';
import {
  getInitialBookingDetails,
  getAutoDiscounts,
  validateDiscountCode,
} from './BookingUtils'
import {
  calculateProRatedCost,
  calculateEndOfMonth,
  applyDiscountToBookingDetails,
} from './BookingActions';

const BookingContext = createContext();

export const useBooking = () => useContext(BookingContext);

const BookingProvider = ({ children }) => {

  const initialBookingDetails = useMemo(() => {
    const savedDetails = sessionStorage.getItem('bookingDetails');
    if (savedDetails) {
      const parsedDetails = JSON.parse(savedDetails);
      if (typeof parsedDetails.startDate === 'string') {
        parsedDetails.startDate = new Date(parsedDetails.startDate);
      }
      return parsedDetails;
    } else {
      return getInitialBookingDetails();  // Gebruik de functie om de initiële staat op te halen
    }
  }, []);
  
  const [bookingDetails, setBookingDetails] = useState(initialBookingDetails);

  useEffect(() => {
    sessionStorage.setItem('bookingDetails', JSON.stringify(bookingDetails));
  }, [bookingDetails]);

  const calculateDeposit = useCallback((updatedExtras, monthlyCosts) => {
    const depositKey = 'b09de76a-ca93-4d92-9a5e-dfca2291e911';
    const depositAmount = monthlyCosts * 2;

    const existingDeposit = updatedExtras.find((e) => e.id === depositKey);

    if (existingDeposit && existingDeposit.price === depositAmount) {
      return updatedExtras;
    }

    if (existingDeposit) {
      return updatedExtras.map((e) =>
        e.id === depositKey ? { ...e, price: depositAmount } : e
      );
    } else {
      return [
        ...updatedExtras,
        { id: depositKey, label: 'Borg', price: depositAmount, type: 'once', quantity: 1 },
      ];
    }
  }, []);

  const calculateEndDate = useCallback((startDate) => {
    const dayOfMonth = startDate.getDate();

    if (dayOfMonth >= 25) {
      return calculateEndOfMonth(new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1));
    } else {
      return calculateEndOfMonth(new Date(startDate.getFullYear(), startDate.getMonth(), 1));
    }
  }, []);

  const formatPeriod = useCallback((startDate, endDate) => {
    const NullDate = () => new Date(0, 0, 0, 0, 0, 0, 0);

    if (startDate.getTime() === NullDate().getTime() || endDate.getTime() === NullDate().getTime()) {
      return "No valid period";
    }

    const formatDateTime = (date, format) => {
      const options = {
        day: format.includes("d") ? 'numeric' : undefined,
        month: format.includes("MMM") ? 'short' : undefined,
        year: format.includes("yyyy") ? 'numeric' : undefined
      };
      return new Intl.DateTimeFormat('nl-NL', options).format(date);
    };

    if (startDate.getFullYear() === endDate.getFullYear() && startDate.getMonth() === endDate.getMonth()) {
      return formatDateTime(startDate, "d") + " - " + formatDateTime(endDate, "d MMM");
    } else if (startDate.getFullYear() === endDate.getFullYear()) {
      return formatDateTime(startDate, "d MMM") + " - " + formatDateTime(endDate, "d MMM yyyy");
    } else {
      return formatDateTime(startDate, "d MMM yyyy") + " - " + formatDateTime(endDate, "d MMM yyyy");
    }
  }, []);
  
  const applyDiscountCode = async (code) => {
    const response = await validateDiscountCode(code);
  
    if (response.success) {
      const discount = response.discount;
  
      // Pas de korting toe via de centrale functie
      const updatedDetails = applyDiscountToBookingDetails(discount, bookingDetails);
  
      // Werk de bookingDetails bij met de uitkomst
      updateBookingDetails(updatedDetails);
  
      return { success: true };
    } else {
      return { success: false, message: response.message };
    }
  }
  
  const applyAutoDiscounts = useCallback((updatedDetails) => {
    const autoDiscounts = getAutoDiscounts();
  
    // Controleer welke kortingen van toepassing zijn
    autoDiscounts.forEach((discount) => {
      if (
        !discount.applicableTo || // Check if applicableTo is not present
        discount.applicableTo.some((extraId) =>
          updatedDetails.selectedExtras.some(
            (extra) => extra.id === extraId && extra.quantity >= discount.min_quantity
          )
        )
      ) {
        updatedDetails = applyDiscountToBookingDetails(discount, updatedDetails);
      }      
    });
  
    return updatedDetails;
  }, []);

  const removeDiscountCode = (discountCode) => {
    updateBookingDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails };
  
      // Filter de korting uit de monthlyDiscount array
      updatedDetails.monthlyDiscount = updatedDetails.monthlyDiscount.filter(
        (discount) => discount.code !== discountCode
      );
  
      // Filter de korting uit de oneTimeDiscount array
      updatedDetails.oneTimeDiscount = updatedDetails.oneTimeDiscount.filter(
        (discount) => discount.code !== discountCode
      );
  
      // Controleer of de korting in de selectedExtras array zit en verwijder deze
      updatedDetails.selectedExtras = updatedDetails.selectedExtras.map((extra) => {
        if (extra.discount && extra.discount.code === discountCode) {
          return {
            ...extra,
            discount: null, // Verwijder de korting van de extra
          };
        }
        return extra;
      });
  
      return updatedDetails;
    });
  };  
    
  const calculateTotalCost = useCallback((details) => {
    const { selectedBox, selectedExtras, monthlyDiscount, oneTimeDiscount, startDate } = details;
  
    if (!selectedBox) {
      return {
        monthlyCosts: 0,
        oneTimeCosts: 0,
        selectedExtras: [],
        monthlyDiscount: monthlyDiscount.map(discount => ({ ...discount, value: 0 })),
        oneTimeDiscount: oneTimeDiscount.map(discount => ({ ...discount, value: 0 })),
      };
    }
  
    const basePrice = selectedBox.price_current || 0;
  
    // Bereken de maandelijkse kosten inclusief kortingen op extra's
    let adjustedExtras = selectedExtras;

    const monthlyBaseCosts = basePrice + adjustedExtras.reduce((total, e) => {
      if (e.type === 'recurring') {
        const extraCost = (e.price || 0) * (e.quantity || 1);
        const discountValue = e.discount ? e.discount.value : 0;
        return total + extraCost - discountValue;
      }
      return total;
    }, 0);
  
    const totalMonthlyDiscountValue = monthlyDiscount.reduce((total, discount) => {
      const discountValue = discount.type === 'percentage'
        ? (monthlyBaseCosts * discount.amount) / 100
        : discount.amount;
      return total + discountValue;
    }, 0);
  
    const adjustedMonthlyCosts = monthlyBaseCosts - totalMonthlyDiscountValue;
  
    adjustedExtras = calculateDeposit(adjustedExtras, monthlyBaseCosts);
  
    const endDate = calculateEndDate(startDate);
    const proRatedCost = calculateProRatedCost(startDate, endDate, adjustedMonthlyCosts);
  
    const formattedPeriod = formatPeriod(startDate, endDate);

    let rentExtra = {
      id: 'proRatedRent',
      label: `Huur ${formattedPeriod}`,
      price: proRatedCost || 0,
      type: 'once',
      quantity: 1,
    };

    rentExtra = applyAutoDiscounts({
      selectedExtras: [rentExtra],
      monthlyDiscount: [],
      oneTimeDiscount: [],      
    });

    rentExtra = rentExtra.selectedExtras[0];

    let updatedExtras = adjustedExtras.filter(e => {
      return e.id !== 'proRatedRent';
    });

    updatedExtras.unshift(rentExtra);

    // Bereken de eenmalige kosten inclusief kortingen op extra's
    const oneTimeBaseCosts = updatedExtras.reduce((total, e) => {
      if (e.type === 'once') {
        const extraCost = (e.price || 0) * (e.quantity || 1);
        const discountValue = e.discount ? e.discount.value : 0;
        return total + extraCost - discountValue;
      }
      return total;
    }, 0);
  
    const totalOneTimeDiscountValue = oneTimeDiscount.reduce((total, discount) => {
      const discountValue = discount.type === 'percentage'
        ? (oneTimeBaseCosts * discount.amount) / 100
        : discount.amount;
      return total + discountValue;
    }, 0);
  
    const adjustedOneTimeCosts = oneTimeBaseCosts - totalOneTimeDiscountValue;
  
    return {
      monthlyCosts: adjustedMonthlyCosts || 0,
      oneTimeCosts: adjustedOneTimeCosts || 0,
      selectedExtras: updatedExtras,
      monthlyDiscount: monthlyDiscount.map(discount => ({
        ...discount,
        discountValue: discount.type === 'percentage'
          ? (monthlyBaseCosts * discount.amount) / 100
          : discount.amount
      })),
      oneTimeDiscount: oneTimeDiscount.map(discount => ({
        ...discount,
        discountValue: discount.type === 'percentage'
          ? (oneTimeBaseCosts * discount.amount) / 100
          : discount.amount
      })),
    };
  }, [calculateDeposit, calculateEndDate, formatPeriod, applyAutoDiscounts]);

  const updateBookingDetails = useCallback(
    (updatedFields) => {
      setBookingDetails((prevDetails) => {
        const updates = typeof updatedFields === 'function' ? updatedFields(prevDetails) : updatedFields;
  
        let updatedDetails = {
          ...prevDetails,
          ...updates,
        };
  
        // Pas eerst de automatische kortingen toe
        updatedDetails = applyAutoDiscounts(updatedDetails);
  
        // Bereken vervolgens de totale kosten met de bijgewerkte details
        const calculatedCosts = calculateTotalCost(updatedDetails);
  
        const finalDetails = {
          ...updatedDetails,
          ...calculatedCosts,
        };
  
        return finalDetails;
      });
    },
    [calculateTotalCost, applyAutoDiscounts]
  );
  
  const resetBookingDetails = useCallback(() => {
    sessionStorage.clear();  // Verwijdert alle items in de sessie-opslag
  
    setBookingDetails(getInitialBookingDetails());  // Reset de staat met de functie
  }, []);
  
  const addUnitToBookingDetails = useCallback(
    (location, box) => {
      const currentBookingDetails = JSON.parse(sessionStorage.getItem('bookingDetails'));
  
      const locationChanged = currentBookingDetails && currentBookingDetails.selectedLocation?.key !== location.key;
      const boxChanged = currentBookingDetails && currentBookingDetails.selectedBox?.key !== box.key;

      if (locationChanged || boxChanged) {
        resetBookingDetails(); // Reset alle gegevens als de locatie of box is veranderd
        updateBookingDetails({
          selectedLocation: location,
          selectedBox: box,
        });
      } else {
        updateBookingDetails({
          selectedLocation: location,
          selectedBox: box,
        });
      }
    },
    [resetBookingDetails, updateBookingDetails]
  );

  const addExtraToBookingDetails = useCallback(
    (extra) => {
      updateBookingDetails((prevDetails) => {
        const existingExtra = prevDetails.selectedExtras.find((e) => e.id === extra.id);

        let updatedExtras;
        if (existingExtra) {
          updatedExtras = prevDetails.selectedExtras.map((e) =>
            e.id === extra.id ? { ...e, quantity: extra.quantity } : e
          );
        } else {
          updatedExtras = [...prevDetails.selectedExtras, extra];
        }

        const btwExtra = updatedExtras.find((e) => e.id === 'btw');
        const borgExtra = updatedExtras.find((e) => e.id === 'b09de76a-ca93-4d92-9a5e-dfca2291e911');

        updatedExtras = updatedExtras.filter(
          (e) => e.id !== 'btw' && e.id !== 'b09de76a-ca93-4d92-9a5e-dfca2291e911'
        );

        if (borgExtra) updatedExtras.unshift(borgExtra);
        if (btwExtra) updatedExtras.unshift(btwExtra);

        return {
          selectedExtras: updatedExtras,
        };
      });
    },
    [updateBookingDetails]
  );

  const removeExtraFromBookingDetails = useCallback(
    (extraId) => {
      updateBookingDetails((prevDetails) => {
        const updatedExtras = prevDetails.selectedExtras.filter((e) => e.id !== extraId);

        return {
          selectedExtras: updatedExtras,
        };
      });
    },
    [updateBookingDetails]
  );

  const togglePrivateStatus = useCallback(() => {
    const newPrivateStatus = !bookingDetails.private;
    updateBookingDetails({
      private: newPrivateStatus,
    });

    if (newPrivateStatus) {
      removeExtraFromBookingDetails('btw');
    } else {
      const btwAmount = bookingDetails.selectedBox ? bookingDetails.selectedBox.price_current * 0.21 : 0;
      addExtraToBookingDetails({
        id: 'btw',
        label: '- Btw',
        price: btwAmount,
        type: 'recurring',
        quantity: 1,
      });
    }
  }, [
    bookingDetails.private,
    bookingDetails.selectedBox,
    addExtraToBookingDetails,
    removeExtraFromBookingDetails,
    updateBookingDetails,
  ]);
  
  return (
    <BookingContext.Provider
      value={{
        bookingDetails,
        addUnitToBookingDetails,
        addExtraToBookingDetails,
        removeExtraFromBookingDetails,
        togglePrivateStatus,
        updateBookingDetails,
        applyDiscountCode,
        removeDiscountCode
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};

export default BookingProvider;
