import React, { useState } from "react";
import { Box, Grid, Button, TextField, Typography, CircularProgress } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useBooking } from "../../context/booking/BookingProvider";

// Yup schema voor validatie
const schema = yup.object().shape({
  discountCode: yup
    .string()
    .test(
      "min-length-or-empty",
      "Kortingscode moet minimaal 4 tekens bevatten",
      value => value === "" || value.length >= 2
    )
});

const ProductDiscount = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { applyDiscountCode } = useBooking();

  const { control, handleSubmit, formState: { errors }, setError, clearErrors, reset } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      discountCode: '',
    }
  });

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const result = await applyDiscountCode(data.discountCode);

    if (result.success) {
      clearErrors("discountCode");
      reset();
    } else {
      setError("discountCode", {
        type: "manual",
        message: result.message,
      });
    }

    setIsSubmitting(false);
  };

  return (
    <Box mt={4}>
      <Typography variant="h6">Kortingscode</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item xs={9}>
            <Controller
              name="discountCode"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: field.value && !errors.discountCode ? '#4caf50' : '',
                      },
                    },
                  }}
                  size="small"
                  placeholder="Voer kortingscode in"
                  error={!!errors.discountCode}  // Show error state if validation fails
                  helperText={errors.discountCode ? errors.discountCode.message : ""}  // Show error message
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? <CircularProgress size={24} /> : <CheckIcon />}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default ProductDiscount;
