import React from 'react';
import { Dialog, Button, Box, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GlassMagnifier } from '@datobs/react-image-magnifiers';
import { useTheme } from '@mui/material/styles';

const ZoomModal = ({ open, onClose, imageSrc, largeImageSrc }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detecteert mobiele schermen

  return (
    <Dialog open={open} onClose={onClose} fullScreen={isMobile}>
      <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
        {/* Omringende box om de afbeelding binnen de schermgrootte te houden */}
        <Box
          sx={{
            maxHeight: '100vh', // Maximale hoogte van het scherm
            maxWidth: '100vw',  // Maximale breedte van het scherm
            display: 'flex',    // Flexbox om de afbeelding te centreren
            justifyContent: 'center', 
            alignItems: 'center',
            overflow: 'hidden'  // Zorgt ervoor dat de afbeelding binnen de grenzen blijft
          }}
        >
          <GlassMagnifier
            imageSrc={imageSrc}
            imageAlt="Example Image"
            largeImageSrc={largeImageSrc}
            magnifierSize="50%"
            magnifierBorderSize={5}
            magnifierBorderColor="rgba(255, 255, 255, 0.5)"
            allowOverflow={true}
            square={false}
            style={{
              maxHeight: '100%', // Behoud de hoogte binnen de omringende box
              maxWidth: '100%',  // Behoud de breedte binnen de omringende box
              height: 'auto',    // Houd de verhoudingen van de afbeelding in stand
              width: 'auto',     // Houd de verhoudingen van de afbeelding in stand
            }}
          />
        </Box>

        {/* Close button */}
        <Button
          onClick={onClose}
          sx={{ position: 'absolute', top: 10, right: 10, zIndex: 1000 }}
        >
          <CloseIcon />
        </Button>
      </Box>
    </Dialog>
  );
};

export default ZoomModal;
