import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Drawer, TextField, Button, Switch } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useBooking } from '../../context/booking/BookingProvider';
import { CustomCard, Title, Content, Right } from '../CustomCard';

const ProductExtras = ({ extras }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedExtra, setSelectedExtra] = useState(null);
  const [tempExtraCount, setTempExtraCount] = useState(0);
  const [isExtraSelected, setIsExtraSelected] = useState(false);
  const { addExtraToBookingDetails, removeExtraFromBookingDetails, bookingDetails } = useBooking();

  useEffect(() => {
    if (tempExtraCount === 0) {
      setIsExtraSelected(false);
    }
  }, [tempExtraCount]);

  const handleDrawerOpen = (extra) => {
    setSelectedExtra(extra);
    const foundExtra = bookingDetails.selectedExtras.find(e => e.id === extra.key);
    if (foundExtra) {
      setTempExtraCount(foundExtra.quantity);
      setIsExtraSelected(foundExtra.quantity > 0);
    } else {
      setTempExtraCount(0);
      setIsExtraSelected(false);
    }
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedExtra(null);
  };

  const handleAddExtra = () => {
    if (tempExtraCount > 0 || isExtraSelected) {
      addExtraToBookingDetails({
        id: selectedExtra.key,
        label: selectedExtra.label,
        price: selectedExtra.price_current,
        type: selectedExtra.price_type === 'once' ? 'once' : 'recurring',
        quantity: tempExtraCount > 0 ? tempExtraCount : 1,
      });
    } else {
      removeExtraFromBookingDetails(selectedExtra.key);
    }
    handleDrawerClose();
  };

  const handleToggleExtra = () => {
    const newQuantity = isExtraSelected ? 0 : 1;
    setTempExtraCount(newQuantity);
    setIsExtraSelected(!isExtraSelected);
  };

  const handleExtraCountChange = (amount) => {
    const newCount = Math.max(0, tempExtraCount + amount);
    setTempExtraCount(newCount);
  };

  const findExtraInBookingDetails = (extraKey) => {
    const foundExtra = bookingDetails.selectedExtras.find(e => e.id === extraKey);
    return foundExtra ? foundExtra.quantity : 0;
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6">Extra's</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
        {extras.map(extra => (
          <CustomCard key={extra.key} onClick={() => handleDrawerOpen(extra)}>
            <Title>{extra.label}</Title>
            <Content>€{parseFloat(extra.price_current).toFixed(2)}</Content>
            <Right>
              {findExtraInBookingDetails(extra.key) > 0 ? (
                <Typography variant="body2" color="primary" sx={{ mb: 1 }}>
                  +€{(extra.price_current * findExtraInBookingDetails(extra.key)).toFixed(2)}
                </Typography>
              ) : null}
              <ArrowForwardIosIcon />
            </Right>
          </CustomCard>
        ))}
      </Box>

      <Drawer
        anchor={window.innerWidth <= 600 ? 'bottom' : 'right'}
        open={drawerOpen}
        onClose={handleDrawerClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: { xs: '100%', sm: '300px' },
            height: { xs: 'auto', sm: '100%' },
            maxHeight: '100%',
          },
        }}
      >
        {selectedExtra && (
          <Box sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6">{selectedExtra.label}</Typography>
              <IconButton onClick={handleDrawerClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography variant="body1" sx={{ mt: 2 }}>
              {selectedExtra.description}
            </Typography>
            {selectedExtra.allowMultiple ? (
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, justifyContent: 'space-between' }}>
                <Typography variant="body1">Aantal</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton onClick={() => handleExtraCountChange(-1)}>
                    <RemoveCircleOutlineIcon fontSize="large" />
                  </IconButton>
                  <TextField
                    value={tempExtraCount}
                    variant="outlined"
                    size="small"
                    sx={{ width: '50px', textAlign: 'center' }}
                    inputProps={{ readOnly: true }}
                  />
                  <IconButton onClick={() => handleExtraCountChange(1)}>
                    <AddCircleOutlineIcon fontSize="large" />
                  </IconButton>
                </Box>
              </Box>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, justifyContent: 'space-between' }}>
                <Typography variant="body1">Toevoegen</Typography>
                <Switch checked={isExtraSelected} onChange={handleToggleExtra} />
              </Box>
            )}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
              onClick={handleAddExtra}
            >
              {tempExtraCount > 0 || isExtraSelected
                ? `Bevestigen: €${(selectedExtra.price_current * (tempExtraCount || 1)).toFixed(2)}`
                : 'Sluiten'}
            </Button>
          </Box>
        )}
      </Drawer>
    </Box>
  );
};

export default ProductExtras;
