import React, { useState } from "react";
import { Box, Card, CardContent, Typography, Drawer, Grid, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useBooking } from "../../context/booking/BookingProvider";
import ProductDiscount from "./ProductDiscount";
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton';

const ProductPriceDetail = ({ children }) => {
  const [open, setOpen] = useState(false);
  const { bookingDetails, removeDiscountCode } = useBooking();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const {
    selectedBox,
    monthlyCosts = 0,
    oneTimeCosts = 0,
    selectedExtras = [],
    monthlyDiscount = [],
    oneTimeDiscount = [],
  } = bookingDetails;

  const recurringExtras = selectedExtras.filter(
    (extra) => extra.type === "recurring"
  );
  const oneTimeExtras = selectedExtras.filter((extra) => extra.type === "once");

  const selectedBoxPrice =
    selectedBox && !isNaN(selectedBox.price_current)
      ? selectedBox.price_current
      : 0;

  const renderPriceDetails = () => (
    <Box>
      <Typography variant="h6">Maandelijkse Kosten</Typography>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="body1">
              {selectedBox ? selectedBox.label : ""}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <Typography variant="body1">
              €{parseFloat(selectedBoxPrice).toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
        {recurringExtras.map((extra, index) => (
          <React.Fragment key={`${extra.key}-${index}`}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography variant="body1">
                  {extra.label} {extra.quantity > 1 ? `${extra.quantity}x` : ""}
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ textAlign: "right" }}>
                <Typography variant="body1">
                  €{(extra.price * extra.quantity).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
            {extra.discount && extra.discount.value > 0 && (
              <Grid container spacing={0}>
                <Grid item xs={8}>
                  <Typography variant="body2" color="textSecondary">
                    Korting: {extra.discount.code}
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{ textAlign: "right", display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <Typography variant="body2" color="textSecondary">
                    -€{extra.discount.value.toFixed(2)}
                  </Typography>
                  {!extra.discount.apply_automatically && (
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => removeDiscountCode(extra.discount.code)}
                        sx={{ p: 0 }}  // Padding reduced to make it smaller
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    )}
                </Grid>
              </Grid>
            )}
          </React.Fragment>
        ))}
        {monthlyDiscount.map((discount, index) => (
          discount.amount > 0 && (
            <Grid container spacing={0} key={`monthlyDiscount-${index}`}>
              <Grid item xs={8}>
                <Typography variant="body2" color="textSecondary">
                  Korting: {discount.code}
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ textAlign: "right", display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Typography variant="body2" color="textSecondary">
                  -€{parseFloat(discount.value).toFixed(2)}
                </Typography>
                {!discount.apply_automatically && (
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => removeDiscountCode(discount.code)}
                        sx={{ p: 0 }}  // Padding reduced to make it smaller
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    )}
              </Grid>
            </Grid>
          )
        ))}
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="h6">Totaal</Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <Typography variant="body1">
              €{parseFloat(monthlyCosts).toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {oneTimeCosts > 0 && (
        <Box mt={4}>
          <Typography variant="h6">Eenmalige Kosten</Typography>
          <Box>
            {oneTimeExtras.map((extra, index) => (
              <React.Fragment key={`${extra.key}-${index}`}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Typography variant="body1">
                      {extra.label} {extra.quantity > 1 ? `${extra.quantity}x` : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: "right" }}>
                    <Typography variant="body1">
                      €{(extra.price * extra.quantity).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
                {extra.discount && extra.discount.value > 0 && (
                  <Grid container spacing={0}>
                    <Grid item xs={8}>
                      <Typography variant="body2" color="textSecondary">
                        Korting: {extra.discount.code}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ textAlign: "right", display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <Typography variant="body2" color="textSecondary">
                        -€{extra.discount.value.toFixed(2)}
                      </Typography>
                      {!extra.discount.apply_automatically && (
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => removeDiscountCode(extra.discount.code)}
                        sx={{ p: 0 }}  // Padding reduced to make it smaller
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    )}
                    </Grid>
                  </Grid>
                )}

              </React.Fragment>
            ))}
            {oneTimeDiscount.map((discount, index) => (
              discount.amount > 0 && (
                <Grid container spacing={0} key={`oneTimeDiscount-${index}`}>
                  <Grid item xs={8}>
                    <Typography variant="body2" color="textSecondary">
                      Korting: {discount.code}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: "right", display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Typography variant="body2" color="textSecondary">
                      -€{parseFloat(discount.value).toFixed(2)}
                    </Typography>
                    {!discount.apply_automatically && (
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => removeDiscountCode(discount.code)}
                        sx={{ p: 0 }}  // Padding reduced to make it smaller
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              )
            ))}
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography variant="h6">Totaal</Typography>
              </Grid>
              <Grid item xs={4} sx={{ textAlign: "right" }}>
                <Typography variant="body1">
                  €{parseFloat(oneTimeCosts).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );

  return (
    <>
      <Box
        sx={{
          display: { xs: "none", md: "block" },
        }}
      >
        <Card>
          <CardContent>
            {renderPriceDetails()}
            <ProductDiscount /> 
          </CardContent>
        </Card>
      </Box>

      <Box
        sx={{
          position: { xs: "fixed", md: "relative" },
          bottom: { xs: 0, md: "auto" },
          left: { xs: 0, md: "auto" },
          width: "100%",
          bgcolor: { xs: "primary.main", md: "transparent" },
          color: "white",
          py: 2,
          textAlign: "center",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={6}
            sx={{ display: { md: "none" } }}
            onClick={open ? handleDrawerClose : handleDrawerOpen}
          >
            {open && (
              <>
                <Typography variant="h6">
                  Sluiten
                </Typography>
              </>
            )}
            {!open && (
              <>
                <Typography variant="h6">
                  {`€${parseFloat(monthlyCosts).toFixed(2)} /mnd`}
                </Typography>
                <Typography variant="h6">
                  {`€${parseFloat(oneTimeCosts).toFixed(2)} /eenmalig`}
                </Typography>
                <Typography variant="body2" sx={{ textDecoration: 'underline' }}>
                  Prijsdetails
                </Typography>
              </>
            )}            
          </Grid>
          <Grid item xs={6} md={12} sx={{ pr: { xs: 2, md: 0 } }}>
            {children}
          </Grid>
        </Grid>
      </Box>

      <Drawer
        anchor="bottom"
        open={open}
        onClose={handleDrawerClose}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
            height: "auto"
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
          >
            <Typography variant="h6">Prijsdetails</Typography>
            <Button onClick={handleDrawerClose}>
              <CloseIcon />
            </Button>
          </Box>
          {renderPriceDetails()}
          <ProductDiscount />
        </Box>
        <Box
          sx={{
            bgcolor: { xs: "primary.main", md: "transparent" },
            color: "white",
            py: 2,
            textAlign: "center",
          }}
        >
          <Grid container>
            <Grid
              item
              xs={6}
              sx={{
                display: { xs: 'flex', md: 'none' },
                textDecoration: 'underline',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={open ? handleDrawerClose : handleDrawerOpen}
            >
              Sluiten
            </Grid>
            <Grid item xs={6} md={12} sx={{ pr: { xs: 2, md: 0 } }}>
              {children}
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </>
  );
};

export default ProductPriceDetail;
