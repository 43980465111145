import React from 'react';
import { Box, Typography } from '@mui/material';

const SimpleFooter = ({ sx = {} }) => (
  <Box
    sx={{
      bgcolor: 'primary.main',
      color: 'white',
      py: 2,
      mt: 'auto',
      ...sx, // Merge the sx prop passed from the parent component
    }}
  >
    <Typography variant="body1" align="center">
      © 2024 Swartbox. Alle rechten voorbehouden.
    </Typography>
  </Box>
);

export default SimpleFooter;
