import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SimpleHeader from '../components/SimpleHeader';
import SimpleFooter from '../components/SimpleFooter';
import { Box, Typography, Grid, Button, Container, MenuItem, FormControl, Select, InputLabel, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import CustomModal from '../components/CustomModal';
import UnitCard from '../components/UnitCard';
import api from '../api';

const ProductList = () => {
  const { locationCode } = useParams();
  const [locationData, setLocationData] = useState(null);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [sortOption, setSortOption] = useState('default');
  const [selectedLabels, setSelectedLabels] = useState([]);

  const handleFilterModalOpen = () => setFilterModalOpen(true);
  const handleFilterModalClose = () => setFilterModalOpen(false);
  const handleSortChange = (event) => setSortOption(event.target.value);
  const handleLabelChange = (event) => {
    const value = event.target.value;
    setSelectedLabels(
      event.target.checked
        ? [...selectedLabels, value]
        : selectedLabels.filter((label) => label !== value)
    );
  };

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const responseLocation = await api.get(`/locations/${locationCode}`);
        const responseSalesItems = await api.get(`/locations/${locationCode}/products`);

        const location = responseLocation.data;

        document.title = `Alle opslagruimtes op locatie ${location.description} | Swartbox`;


        setLocationData({
          ...location,
          salesItems: responseSalesItems.data
        });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchLocationData();
  }, [locationCode]);

  const getSortedSalesItems = () => {
    if (!locationData || !locationData.salesItems) return [];

    const salesItems = [...locationData.salesItems].filter(item =>
      selectedLabels.length === 0 || selectedLabels.includes(item.unit.label)
    );
    
    if (sortOption === 'priceDesc') {
      salesItems.sort((a, b) => parseFloat(b.price_current) - parseFloat(a.price_current));
    }
    return salesItems;
  };

  const renderSalesItems = () => {
    const sortedSalesItems = getSortedSalesItems();
    return (
      <Grid container spacing={2}>
        {sortedSalesItems.map(salesItem => (
          <Grid item key={salesItem.key} xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
            <UnitCard salesItem={salesItem} locationCode={locationCode} />
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderFilters = () => (
    <FormGroup>
      {locationData && locationData.salesItems && Array.from(new Set(locationData.salesItems.map(item => item.unit.label))).map(label => (
        <FormControlLabel
          key={label}
          control={
            <Checkbox
              checked={selectedLabels.includes(label)}
              onChange={handleLabelChange}
              value={label}
            />
          }
          label={label}
        />
      ))}
    </FormGroup>
  );

  const renderLocationSection = () => {
    if (locationData) {
      const filteredSalesItems = getSortedSalesItems(); // Krijg de gefilterde en gesorteerde sales items
  
      return (
        <section>
          <Typography variant="h4">
            Opslagruimtes {locationData.description}
          </Typography>
          <Typography variant="body1">
            Hier kun je de opslagruimtes van de locatie {locationData.description} zien.
          </Typography>
          <Button variant="outlined" startIcon={<FilterListIcon />} onClick={handleFilterModalOpen} sx={{ display: { sm: 'none' } }}>
            Filters
          </Button>
          <Box 
            sx={{ 
              display: { xs: 'flex', sm: 'none' }, // toont op mobiel, verbergt op small (sm) en groter
              alignItems: 'center', justifyContent: 'center', my: 2 }}>
            <Box sx={{ flex: 1, borderBottom: '1px solid black' }} />
            <Typography variant="h6" sx={{ mx: 2 }}>
              {filteredSalesItems.length} Sales Items
            </Typography>
            <Box sx={{ flex: 1, borderBottom: '1px solid black' }} />
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="body1">
              {filteredSalesItems.length} beschikbare units
            </Typography>
            <FormControl variant="outlined" size="small">
              <InputLabel>Sorteren</InputLabel>
              <Select
                value={sortOption}
                onChange={handleSortChange}
                label="Sorteren"
              >
                <MenuItem value="default">Standaard</MenuItem>
                <MenuItem value="priceDesc">Prijs: Hoog naar Laag</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {renderSalesItems()}
        </section>
      );
    }
    return null;
  };
  

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <SimpleHeader />
        <Container sx={{ py: {xs: 1, md: 4} }}>
            <Grid container spacing={2}>
                <Grid item sx={{ display: {xs: 'none', md: 'block'}}} sm={2}>
                  <Typography variant="h6">Filters</Typography>
                  {renderFilters()}
                </Grid>
                <Grid item xs={12} sm={10}>
                    {renderLocationSection()}
                </Grid>
            </Grid>
        </Container>
      <SimpleFooter />
      
      <CustomModal 
        open={filterModalOpen} 
        handleClose={handleFilterModalClose} 
        title="Filters" 
        footer={<Button variant="contained" color="primary" onClick={handleFilterModalClose}>Zoeken</Button>}
      >
        {renderFilters()}
      </CustomModal>
    </Box>
  );
};

export default ProductList;
