import React, { createContext, useContext, useState, useEffect } from 'react';

// Maak de UserDetailsContext aan
const UserDetailsContext = createContext();

// Hook om de UserDetailsContext te gebruiken
export const useUserDetails = () => useContext(UserDetailsContext);

// UserDetailsProvider component dat de context aanbiedt aan de rest van de applicatie
const UserDetailsProvider = ({ children }) => {
  const initialUserDetails = {
    MDRelationKey: null,
    firstName: '',
    phoneNumber: '',
    postalCode: '',
    houseNumber: '',
    houseNumberAddition: '',
    street: '',
    city: '',
    kvkNumber: '',
    tradingName: '',
    email: ''
  };

  const [userDetails, setUserDetails] = useState(() => {
    // Check of er al data in sessionStorage zit, zo ja, laad dat
    const savedUserDetails = sessionStorage.getItem('userDetails');
    return savedUserDetails ? JSON.parse(savedUserDetails) : initialUserDetails;
  });

  // Sla de gebruikersgegevens op in sessionStorage wanneer ze worden bijgewerkt
  useEffect(() => {
    sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
  }, [userDetails]);

  // Functie om de gebruikersgegevens bij te werken
  const updateUserDetails = (updatedFields) => {
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      ...updatedFields,
    }));
  };

  // Functie om alle gebruikersgegevens te resetten (bijv. bij uitloggen)
  const resetUserDetails = () => {
    setUserDetails(initialUserDetails);
    sessionStorage.removeItem('userDetails');
  };

  return (
    <UserDetailsContext.Provider
      value={{
        userDetails,
        updateUserDetails,
        resetUserDetails,
      }}
    >
      {children}
    </UserDetailsContext.Provider>
  );
};

export default UserDetailsProvider;
