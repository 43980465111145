import React, { useState, useEffect } from 'react';
import { Box, Button, Drawer, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import nlLocale from 'date-fns/locale/nl';
import { endOfMonth, addMonths, isToday, isTomorrow, addDays, isSameDay } from 'date-fns';
import { useBooking } from '../../context/booking/BookingProvider'; // Import the useBooking hook

const ProductStartDate = () => {
  const { bookingDetails, updateBookingDetails } = useBooking(); // Access updateBookingDetails from context
  const { startDate } = bookingDetails;
  const [calendarDrawerOpen, setCalendarDrawerOpen] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);

  useEffect(() => {
    if (isToday(startDate)) {
      setSelectedButton('today');
    } else if (isTomorrow(startDate)) {
      setSelectedButton('tomorrow');
    } else if (isSameDay(startDate, addDays(new Date(), 2))) {
      setSelectedButton('dayAfterTomorrow');
    } else {
      setSelectedButton('custom');
    }
  }, [startDate]);

  const handleCalendarDrawerOpen = () => {
    setCalendarDrawerOpen(true);
  };

  const handleCalendarDrawerClose = () => {
    setCalendarDrawerOpen(false);
  };

  const handleDateChange = (newValue) => {
    updateBookingDetails({ startDate: newValue });
  };

  const handleButtonClick = (buttonType) => {
    let newDate;
    switch (buttonType) {
      case 'today':
        newDate = new Date();
        break;
      case 'tomorrow':
        newDate = addDays(new Date(), 1);
        break;
      case 'dayAfterTomorrow':
        newDate = addDays(new Date(), 2);
        break;
      default:
        newDate = new Date();
    }
    updateBookingDetails({ startDate: newDate });
    setSelectedButton(buttonType);
  };

  return (
    <>
      <Typography variant="h6" sx={{ mt: 4 }}>
        Startdatum
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, mt: 2 }}>
        <Button
          variant={selectedButton === 'today' ? 'contained' : 'outlined'}
          onClick={() => handleButtonClick('today')}
        >
          Vandaag
        </Button>
        <Button
          variant={selectedButton === 'tomorrow' ? 'contained' : 'outlined'}
          onClick={() => handleButtonClick('tomorrow')}
        >
          Morgen
        </Button>
        <Button
          variant={selectedButton === 'dayAfterTomorrow' ? 'contained' : 'outlined'}
          onClick={() => handleButtonClick('dayAfterTomorrow')}
        >
          Overmorgen
        </Button>
        <Button
          variant={selectedButton === 'custom' ? 'contained' : 'outlined'}
          onClick={handleCalendarDrawerOpen}
        >
          {selectedButton === 'custom' && startDate ? startDate.toLocaleDateString('nl-NL') : 'Kies zelf een datum'}
        </Button>
      </Box>

      <Drawer
        anchor={window.innerWidth <= 600 ? 'bottom' : 'right'}
        open={calendarDrawerOpen}
        onClose={handleCalendarDrawerClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: { xs: '100%', sm: '400px' },
            height: { xs: 'auto', sm: '100%' },
            maxHeight: '100%',
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Kies zelf een datum</Typography>
            <IconButton onClick={handleCalendarDrawerClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Selecteer een datum die u het beste uitkomt
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={nlLocale}>
            <DateCalendar
              value={startDate}
              onChange={handleDateChange}
              views={['day']}
              minDate={new Date()}
              maxDate={endOfMonth(addMonths(new Date(), 2))}
              disablePast
            />
          </LocalizationProvider>
          <Button variant="contained" color="primary" fullWidth sx={{ mt: 2 }} onClick={handleCalendarDrawerClose}>
            Bevestigen
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

export default ProductStartDate;
