import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Drawer, IconButton, TextField } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useBooking } from '../../context/booking/BookingProvider';
import { CustomCard, Title, Content, Right } from '../CustomCard';

const ProductExtraUser = ({ extraUser }) => {
  const [extraUserDrawerOpen, setExtraUserDrawerOpen] = useState(false);
  const [tempExtraUserCount, setTempExtraUserCount] = useState(0);
  const { addExtraToBookingDetails, removeExtraFromBookingDetails, bookingDetails } = useBooking();

  useEffect(() => {
    const foundExtraUser = bookingDetails.selectedExtras.find(e => e.id === extraUser.key);
    if (foundExtraUser) {
      setTempExtraUserCount(foundExtraUser.quantity);
    } else {
      setTempExtraUserCount(0);
    }
  }, [bookingDetails, extraUser.key]);

  const handleExtraUserDrawerOpen = () => {
    setExtraUserDrawerOpen(true);
  };

  const handleExtraUserDrawerClose = () => {
    setExtraUserDrawerOpen(false);
  };

  const handleExtraUserChange = (amount) => {
    const newCount = Math.max(0, tempExtraUserCount + amount);
    setTempExtraUserCount(newCount);
  };

  const handleConfirm = () => {
    if (tempExtraUserCount > 0) {
      addExtraToBookingDetails({
        id: extraUser.key,
        label: extraUser.label,
        price: extraUser.price_current,
        type: 'recurring',
        quantity: tempExtraUserCount,
      });
    } else {
      removeExtraFromBookingDetails(extraUser.key);
    }
    handleExtraUserDrawerClose();
  };

  const findExtraInBookingDetails = (extraKey) => {
    const foundExtra = bookingDetails.selectedExtras.find(e => e.id === extraKey);
    return foundExtra ? foundExtra.quantity : 0;
  };

  return (
    <>   
      <Typography variant="h6" sx={{ mt: 4 }}>
        Toegang
      </Typography>
      <CustomCard onClick={handleExtraUserDrawerOpen} sx={{ mt: 2 }}>
        <Title>{extraUser.label}</Title>
        <Content>€{extraUser.price_current.toFixed(2)} /mnd</Content>
        <Right>
          {findExtraInBookingDetails(extraUser.key) > 0 ? (
            <Typography variant="body2" color="primary" sx={{ mb: 1 }}>
              +€{(extraUser.price_current * findExtraInBookingDetails(extraUser.key)).toFixed(2)}
            </Typography>
          ) : null}
          <ArrowForwardIosIcon />
        </Right>
      </CustomCard>

      <Drawer
        anchor={window.innerWidth <= 600 ? 'bottom' : 'right'}
        open={extraUserDrawerOpen}
        onClose={handleExtraUserDrawerClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: { xs: '100%', sm: '300px' },
            height: { xs: 'auto', sm: '100%' },
            maxHeight: '100%',
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">{extraUser.label}</Typography>
            <IconButton onClick={handleExtraUserDrawerClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Gedetailleerde informatie over het toevoegen van een extra gebruiker...
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, justifyContent: 'space-between' }}>
            <Typography variant="body1">Extra gebruiker</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={() => handleExtraUserChange(-1)}>
                <RemoveCircleOutlineIcon fontSize="large" />
              </IconButton>
              <TextField
                value={tempExtraUserCount}
                variant="outlined"
                size="small"
                sx={{ width: '50px', textAlign: 'center' }}
                inputProps={{ readOnly: true }}
              />
              <IconButton onClick={() => handleExtraUserChange(1)}>
                <AddCircleOutlineIcon fontSize="large" />
              </IconButton>
            </Box>
          </Box>
          <Button 
            variant="contained" 
            color="primary" 
            fullWidth 
            sx={{ mt: 2 }}
            onClick={handleConfirm}
          >
            {tempExtraUserCount > 0 ? `Bevestigen: €${(extraUser.price_current * tempExtraUserCount).toFixed(2)}` : 'Sluiten'}
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

export default ProductExtraUser;
