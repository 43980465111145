import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Typography, Tooltip, Badge, Button, ClickAwayListener, Grid } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Swiper from './Swiper';
import CustomModal from './CustomModal';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const UnitCard = ({ salesItem, locationCode }) => {
  const navigate = useNavigate();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  const handleTooltipClose = () => setTooltipOpen(false);
  const handleTooltipOpen = () => setTooltipOpen(true);
  const handleInfoModalOpen = () => setInfoModalOpen(true);
  const handleInfoModalClose = () => setInfoModalOpen(false);

  const handleSelectClick = () => {
    navigate(`/product/${locationCode}/${salesItem.key}`);
  };

  return (
    <>
      <Card sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        {salesItem.swiper && salesItem.swiper.length > 0 && (
          <Swiper items={salesItem.swiper} />
        )}
        <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box>
                <Typography variant="h5">
                  {salesItem.label}
                </Typography>
                <Typography variant="body2">
                  {salesItem.unit.surface}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} justify="flex-end">
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                {salesItem.price_before && parseFloat(salesItem.price_before) !== 0.00 && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" style={{ textDecoration: 'line-through' }}>
                      € {Math.floor(salesItem.price_before)} /mnd
                    </Typography>
                  </Box>
                )}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body1">
                    € {Math.floor(salesItem.price_current)} /mnd
                  </Typography>
                </Box>
                {salesItem.offer && (
                  <ClickAwayListener onClickAway={handleTooltipClose}>
                    <Tooltip
                      title={salesItem.offer}
                      arrow
                      open={tooltipOpen}
                      onClose={handleTooltipClose}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      PopperProps={{
                        popperOptions: {
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, -10], // verander deze waarden om te verschuiven
                              },
                            },
                          ],
                        },
                      }}
                    >
                      <Typography variant="body2" color="secondary" onClick={handleTooltipOpen} sx={{ display: 'flex', alignItems: 'center' }}>
                        Aanbieding
                        <InfoIcon color="secondary" sx={{ marginLeft: 1 }} />
                      </Typography>
                    </Tooltip>
                  </ClickAwayListener>
                )}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>{salesItem.badge_value && (
                  <Badge badgeContent={salesItem.badge_value} color={salesItem.badge_color || 'default'} sx={{
                      marginTop: 1,
                      '& .MuiBadge-badge': {
                      transform: 'none',
                      position: 'relative',
                    },
                  }} />
                )}</Box>
              </Box>
            </Grid>
          </Grid> 

          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={6}>
              <Box display="flex" flexDirection="column" >
                <Button variant="contained" color="primary" onClick={handleInfoModalOpen}>Meer Info</Button>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" flexDirection="column" >
                <Button variant="contained" color="secondary" onClick={handleSelectClick}>Selecteer</Button>
              </Box>
            </Grid>
          </Grid>

        </CardContent>
      </Card>

      <CustomModal 
        open={infoModalOpen} 
        handleClose={handleInfoModalClose} 
        title={salesItem.label} 
        fullScreen
        sx={{ width: { xs: '100%', md: 800 }, bgcolor: 'background.default' }}
      >
        <Box sx={{ width: '100%', margin: '0 auto', p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              {/* Vervang de URL door de daadwerkelijke URL van de YouTube-video */}
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/VIDEO_ID"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" sx={{ mb: 2 }}>{salesItem.unit.description_short}</Typography>
              <Typography variant="h6">Afmetingen</Typography>
              <Box component="table" sx={{ width: '100%', mb: 2 }}>
                <Box component="tbody">
                  <Box component="tr">
                    <Box component="td" sx={{ pr: 2 }}>Oppervlakte</Box>
                    <Box component="td">{salesItem.unit.surface}</Box>
                  </Box>
                  <Box component="tr">
                    <Box component="td" sx={{ pr: 2 }}>Inhoud</Box>
                    <Box component="td">{salesItem.unit.cubic}</Box>
                  </Box>
                  <Box component="tr">
                    <Box component="td" sx={{ pr: 2 }}>Lengte</Box>
                    <Box component="td">{salesItem.unit.length}</Box>
                  </Box>
                  <Box component="tr">
                    <Box component="td" sx={{ pr: 2 }}>Breedte</Box>
                    <Box component="td">{salesItem.unit.width}</Box>
                  </Box>
                  <Box component="tr">
                    <Box component="td" sx={{ pr: 2 }}>Hoogte</Box>
                    <Box component="td">{salesItem.unit.height}</Box>
                  </Box>
                </Box>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    {salesItem.price_before && parseFloat(salesItem.price_before) !== 0.00 && (
                      <Typography variant="body1" style={{ textDecoration: 'line-through' }}>
                        € {Math.floor(salesItem.price_before)} /mnd
                      </Typography>
                    )}
                    <Typography variant="body1" sx={{ mt: salesItem.price_before ? 1 : 0 }}>
                      € {Math.floor(salesItem.price_current)} /mnd
                    </Typography>
                    {salesItem.offer && (
                      <ClickAwayListener onClickAway={handleTooltipClose}>
                        <Tooltip
                          title={salesItem.offer}
                          arrow
                          open={tooltipOpen}
                          onClose={handleTooltipClose}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          PopperProps={{
                            popperOptions: {
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [0, -10], // verander deze waarden om te verschuiven
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <Typography variant="body2" color="secondary" onClick={handleTooltipOpen} sx={{ display: 'flex', alignItems: 'center' }}>
                            Aanbieding
                            <InfoIcon color="secondary" sx={{ marginLeft: 1 }} />
                          </Typography>
                        </Tooltip>
                      </ClickAwayListener>
                    )}
                    {salesItem.badge_value && (
                        <Badge badgeContent={salesItem.badge_value} color={salesItem.badge_color || 'default'} sx={{
                            marginTop: 1,
                            '& .MuiBadge-badge': {
                              transform: 'none',
                              position: 'relative',
                            },
                          }}
                        />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: { xs: 'center', sm: 'flex-end' } }}>
                  <Button 
                    variant="contained" 
                    color="secondary" 
                    onClick={handleSelectClick} 
                    sx={{ mb: 1, width: { xs: '100%', sm: 'auto' } }} 
                    endIcon={<ArrowForwardIcon />}
                  >
                    Kies deze unit
                  </Button>
                  <Button 
                    variant="outlined" 
                    color="primary" 
                    sx={{ width: { xs: '100%', sm: 'auto' } }} 
                    startIcon={<WhatsAppIcon />}
                  >
                    Stel een vraag via WhatsApp
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </CustomModal>
    </>
  );
};

export default UnitCard;
