import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SimpleHeader from '../components/SimpleHeader';
import SimpleFooter from '../components/SimpleFooter';
import { Box, Card, CardContent, CircularProgress, Container, Grid, Typography, Button } from '@mui/material';
import api from '../api';
import ProductHeader from '../components/product/ProductHeader';
import ProductExtraUser from '../components/product/ProductExtraUser';
import ProductStartDate from '../components/product/ProductStartDate';
import ProductPriceDetail from '../components/product/ProductPriceDetail';
import ProductExtras from '../components/product/ProductExtras';
import ProductSelectUnit from '../components/product/ProductSelectUnit';
import ProductSignup from '../components/product/ProductSignup';
import ProductRentPrivate from '../components/product/ProductRentPrivate'; // Importeer het nieuwe component
import { useBooking } from '../context/booking/BookingProvider';
import { useNavigate } from "react-router-dom";

const Product = () => {
  const { locationCode, salesItemKey } = useParams();
  const { addUnitToBookingDetails, addExtraToBookingDetails } = useBooking();
  const [salesItemData, setSalesItemData] = useState(null);
  const [locationData, setLocationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [availableUnits, setAvailableUnits] = useState([]);
  const [unitsLoading, setUnitsLoading] = useState(true);
  const [unitsAvailable, setUnitsAvailable] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Kies de gewenste extra's | Swartbox`;

    const fetchData = async () => {
      try {
        const responseSalesItem = await api.get(`/locations/${locationCode}/products/${salesItemKey}`);
        const responseLocation = await api.get(`/locations/${locationCode}`);

        setSalesItemData(responseSalesItem.data);
        setLocationData(responseLocation.data);
        setLoading(false);

        addUnitToBookingDetails(responseLocation.data, responseSalesItem.data);

        try {
          const responseAvailableUnits = await api.get(`/locations/${locationCode}/products/${salesItemKey}/listAvailable`);
          setAvailableUnits(responseAvailableUnits.data);
          setUnitsAvailable(true);
        } catch (error) {
          if (error.response && error.response.status === 404) {
            setUnitsAvailable(false);
          } else {
            console.error('Error fetching available units:', error);
          }
        }
        setUnitsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
        setUnitsLoading(false);
      }
    };

    fetchData();
  }, [locationCode, salesItemKey, addUnitToBookingDetails]);

  const handleUnitSelect = (unitId) => {
    setSelectedUnit(unitId);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!salesItemData || !locationData) {
    return <Typography>Laden...</Typography>;
  }

  const { extras } = salesItemData;

  // Controleer of de specifieke extra product key aanwezig is voor de unit
  const specificUnitExtra = extras.find(extra => extra.key === '4e863de2-f03d-4a4d-9591-97cbc58d5878');

  // Controleer of de specifieke extra product key aanwezig is voor de extra gebruiker
  const extraUser = extras.find(extra => extra.key === '4bc6fbb2-dfda-4e37-8655-c6fceb012779');

  // Filter de extra's om de specifieke key uit te sluiten
  const filteredExtras = extras.filter(extra => extra.key !== '4bc6fbb2-dfda-4e37-8655-c6fceb012779' && extra.key !== '4e863de2-f03d-4a4d-9591-97cbc58d5878');

  const handleNextClick = () => {
    navigate("/user/login");
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh',  pb: { xs: '110px', md: 0 }, }}>
      <SimpleHeader />
      <Container sx={{ py: { xs: 1, md: 4 } }}>
        {unitsLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
            <CircularProgress />
          </Box>
        ) : (
          unitsAvailable ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                  <ProductHeader salesItem={salesItemData} location={locationData} />
                  <ProductStartDate />
                  {specificUnitExtra && (
                    <ProductSelectUnit
                      units={availableUnits}
                      selectedUnit={selectedUnit}
                      onSelectUnit={handleUnitSelect}
                      unitPrice={specificUnitExtra.price_current}
                    />
                  )}
                  <ProductRentPrivate />
                  {extraUser && <ProductExtraUser extraUser={extraUser} />}
                  <ProductExtras extras={filteredExtras} addExtra={addExtraToBookingDetails} />
                </Grid>
                <Grid item xs={12} md={3}>
                <ProductPriceDetail>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={handleNextClick}
                  >
                    Volgende
                  </Button>
                </ProductPriceDetail>

                  <Card>
                    <CardContent>
                      <Typography variant="h6">
                        Altijd inbegrepen
                      </Typography>
                      <Box sx={{ mt: 2 }}>
                        <ul>
                          <li>Voorbeeld 1</li>
                          <li>Voorbeeld 2</li>
                          <li>Voorbeeld 3</li>
                        </ul>
                      </Box>
                    </CardContent>
                  </Card>
                  <Card sx={{ mt: 2 }}>
                    <CardContent>
                      <Box sx={{ mt: 4, textAlign: 'center' }}>
                        <Typography variant="h6">Veilig betalen met iDEAL</Typography>
                        <img src="/images/ideal-logo.webp" alt="iDEAL logo" style={{ marginTop: '16px', maxWidth: '200px' }} />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={9}>
                <ProductSignup salesItem={salesItemData} location={locationData} />
              </Grid>
            </Grid>
          )
        )}
      </Container>
      <SimpleFooter sx={{ display: { xs: 'none', md: 'block' } }} />
    </Box>
  );
};

export default Product;
