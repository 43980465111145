// src/components/Swiper.js
import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../styles/Swiper.css';

const Swiper = ({ items }) => {
  const settings = {
    dots: items.length > 1, // Alleen dots tonen als er meer dan 1 item is
    infinite: items.length > 1, // Alleen infinite scroll als er meer dan 1 item is
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: items.length > 1 // Alleen pijlen tonen als er meer dan 1 item is
  };

  return (
    <Slider {...settings}>
      {items.map((item, index) => (
        <div key={index}>
          <div dangerouslySetInnerHTML={{ __html: item }} style={{ height: '200px', width: '100%' }} />
        </div>
      ))}
    </Slider>
  );
};

export default Swiper;
