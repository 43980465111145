import React from 'react';
import { Typography, Switch, Box } from '@mui/material';
import { CustomCard, Title, Right } from '../CustomCard'; // Zorg ervoor dat je de CustomCard correct importeert
import { useBooking } from '../../context/booking/BookingProvider';

const ProductRentPrivate = () => {
  const { bookingDetails, togglePrivateStatus } = useBooking();
  const isPrivate = bookingDetails.private;
  
  const handleSwitchChange = () => {
    togglePrivateStatus();
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6">Huurtype</Typography>
      <CustomCard sx={{ mt: 2, cursor: 'default', }} >
        <Title>Zakelijk huren</Title>
        <Right>
          <Switch
            checked={!isPrivate}
            onChange={handleSwitchChange}
            color="primary"
          />
        </Right>
      </CustomCard>
    </Box>
  );
};

export default ProductRentPrivate;
