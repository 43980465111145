import React, { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography, Button, Card, CardContent, Grid, Link } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import ZoomModal from '../ZoomModal';
import { useBooking } from '../../context/booking/BookingProvider';

const ProductSelectUnit = ({ units, unitPrice, onSelectUnit }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { bookingDetails, updateBookingDetails } = useBooking();
  const { unitKey } = bookingDetails; // Haal de unitKey op uit de bookingDetails

  useEffect(() => {
    // Controleer of de geselecteerde unit nog beschikbaar is
    if (unitKey) {
      const selectedUnit = units.find(unit => unit.key === unitKey);
      
      if (!selectedUnit) {
        // Als de unit niet meer beschikbaar is, verwijder hem uit de context
        updateBookingDetails({
          unitKey: null,
          selectedExtras: bookingDetails.selectedExtras.filter(extra => extra.key !== '4e863de2-f03d-4a4d-9591-97cbc58d5878'),
        });
      } else {
        // Als de unit nog beschikbaar is, stel deze in
        onSelectUnit(selectedUnit.key);
      }
    }
  }, [unitKey, units, onSelectUnit, updateBookingDetails, bookingDetails.selectedExtras]);

  const handleUnitChange = (event) => {
    const selectedUnitKey = event.target.value;
    const selectedUnit = units.find(unit => unit.key === selectedUnitKey);

    updateBookingDetails((prevDetails) => {
      const updatedExtras = [...prevDetails.selectedExtras];
      const existingUnitExtraIndex = updatedExtras.findIndex(extra => extra.key === '4e863de2-f03d-4a4d-9591-97cbc58d5878');

      if (selectedUnitKey === '') {
        if (existingUnitExtraIndex > -1) {
          updatedExtras.splice(existingUnitExtraIndex, 1);
        }
      } else {
        const unitExtra = {
          key: '4e863de2-f03d-4a4d-9591-97cbc58d5878',
          label: `Unitnummer ${selectedUnit.code}`,
          price: unitPrice,
          type: 'once',
          quantity: 1,
        };

        if (existingUnitExtraIndex > -1) {
          updatedExtras[existingUnitExtraIndex] = unitExtra;
        } else {
          updatedExtras.push(unitExtra);
        }
      }

      return {
        unitKey: selectedUnitKey,
        selectedExtras: updatedExtras,
      };
    });

    onSelectUnit(selectedUnitKey);
  };

  const getUnitLabel = () => {
    return `(${units.length} beschikbaar)`;
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6">Kies een Box</Typography>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={4} md={3}>
              <Box sx={{ position: 'relative', width: '100%', height: 'auto' }}>
                <ControlledZoom>
                  <img
                    src="/images/plattegrond.jpg"
                    alt="Plattegrond"
                    style={{ width: '100%', maxHeight: '100px', objectFit: 'cover', cursor: 'zoom-in' }}
                    onClick={() => setModalOpen(true)}
                  />
                </ControlledZoom>
                <Button
                  variant="contained"
                  startIcon={<SearchIcon />}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                  }}
                  onClick={() => setModalOpen(true)}
                >
                  Plattegrond
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8} md={5}>
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Typography variant="body1">Je kunt je gewenste unit nummer selecteren.</Typography>
                <Link component="button" variant="body2" sx={{ mt: 1 }} onClick={() => setModalOpen(true)}>
                  Toon plattegrond van de locatie
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Typography variant="body1">Unitnummer</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1">€{parseFloat(unitPrice).toFixed(2)}</Typography>
                  </Grid>
                </Grid>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel id="select-unit-label">{getUnitLabel()}</InputLabel>
                  <Select
                    labelId="select-unit-label"
                    value={units.some(unit => unit.key === unitKey) ? unitKey : ''} // Check of unitKey geldig is
                    label={getUnitLabel()}
                    onChange={handleUnitChange}
                  >
                    <MenuItem key="none" value="">
                      Geen selectie
                    </MenuItem>
                    {units.map((unit) => (
                      <MenuItem key={unit.key} value={unit.key}>
                        {unit.code}
                      </MenuItem>
                    ))}
                  </Select>

                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <ZoomModal open={modalOpen} onClose={() => setModalOpen(false)} imageSrc="/images/plattegrond.jpg" largeImageSrc="/images/plattegrond2.jpg" />
    </Box>
  );
};

export default ProductSelectUnit;
