import React from 'react';
import { Card, CardContent, Box, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const CustomCard = ({ onClick, to, sx, children }) => {
  const cardProps = to
    ? { component: RouterLink, to }
    : { onClick };

  const renderSection = (section) => {
    return React.Children.map(children, (child) => {
      if (child.type.displayName === section) {
        return child;
      }
      return null;
    });
  };

  return (
    <Card {...cardProps} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', textDecoration: 'none', ...sx }}>
      <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <Box sx={{ marginRight: 2 }}>{renderSection('Left')}</Box>
        <Box sx={{ flex: 1 }}>
          {renderSection('Title')}
          {renderSection('Content')}
        </Box>
        <Box sx={{ marginLeft: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>{renderSection('Right')}</Box>
      </CardContent>
    </Card>
  );
};

const Left = ({ children }) => <>{children}</>;
Left.displayName = 'Left';

const Title = ({ children }) => <Typography variant="h6">{children}</Typography>;
Title.displayName = 'Title';

const Content = ({ children }) => <Typography variant="body2">{children}</Typography>;
Content.displayName = 'Content';

const Right = ({ children }) => <>{children}</>;
Right.displayName = 'Right';

export { CustomCard, Left, Title, Content, Right };
