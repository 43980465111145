export const getInitialBookingDetails = () => ({
    selectedLocation: null,
    selectedBox: null,
    private: true,
    unitKey: null,
    startDate: new Date(),
    monthlyCosts: 0,
    monthlyDiscount: [],
    oneTimeCosts: 0,
    oneTimeDiscount: [],
    selectedExtras: [],
  }); 
  
  export const getAutoDiscounts = () => {
    // Simuleer het ophalen van automatische kortingscodes uit een database of API
    // Dit zou normaal via een API-call gaan en dynamisch worden opgehaald
    return [
      {
        code: 'FREE_LOCK',
        type: 'fixed',
        amount: 100,
        scope: 'once',
        apply_automatically: true,
        applicableTo: ['90544a07-266a-40b3-883c-49188d885732'],
        min_quantity: 1 
      },
      {
        code: 'FREE_USER',
        type: 'fixed',
        amount: 7.5,
        scope: 'crecurringe',
        apply_automatically: true,
        applicableTo: ['4bc6fbb2-dfda-4e37-8655-c6fceb012779'],
        min_quantity: 1,
        max_quantity: 1
      },
      {
        code: 'FREEFIRST',
        type: 'percentage',
        amount: 100,
        scope: 'once',
        apply_automatically: true,
        applicableTo: ["proRatedRent"],
        min_quantity: 1
      },
      // {
      //   code: '1EURO',
      //   type: 'percentage',
      //   amount: 99,
      //   scope: 'once',
      //   apply_automatically: true,
      //   //applicableTo: ["proRatedRent"],
      //   min_quantity: 1
      // },
    ];
  };

  export const validateDiscountCode = async (code) => {
    // Simuleer een API-aanroep met hardcoded data
    const validCodes = [
      {
        code: "SAVE10",
        type: "percentage",
        amount: 10,
        scope: "recurring",
        min_quantity: 1,
        applicableTo: ["f74809b3-bed0-4ba8-a758-3b6469ef0d8d"],
      },
      {
        code: "FIXED50",
        type: "fixed",
        amount: 50,
        scope: "once",
      },
      {
        code: "FIXED502",
        type: "fixed",
        amount: 50,
        scope: "recurring",
      },
    ];
  
    // Simuleer een vertraging om de API-aanroep te simuleren
    await new Promise((resolve) => setTimeout(resolve, 500));
  
    // Zoek naar de kortingscode in de array
    const discount = validCodes.find((item) => item.code.toUpperCase() === code.toUpperCase());
    
    if (discount) {
      return { success: true, discount };
    } else {
      return { success: false, message: "Ongeldige kortingscode" };
    }
  };  