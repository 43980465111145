import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';

import Home from './pages/Home';
import ProductList from './pages/ProductList';
import Product from './pages/Product';
import Login from './components/user/Login';
import Register from './components/user/Register';
import Scan from './components/user/Scan';
import UserProfile from './components/user/UserProfile';
import ScrollToTop from './components/ScrollToTop';
import BookingProvider from './context/booking/BookingProvider';
import UserDetailProvider from './context/UserDetail';
import Checkout from './pages/Checkout';

import theme from './theme';

import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <BookingProvider>
          <UserDetailProvider>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/product-list/:locationCode" element={<ProductList />} />
              <Route path="/product/:locationCode/:salesItemKey" element={<Product />} />
              <Route path="/user/login" element={<Login />} />
              <Route path="/user/register" element={<Register />} />
              <Route path="/user/scan" element={<Scan />} />
              <Route path="/user/profile" element={<UserProfile />} />
              <Route path="/checkout" element={<Checkout />} />
            </Routes>
          </UserDetailProvider>
        </BookingProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
