import React from 'react';
import { Typography } from '@mui/material';

const ProductHeader = ({ salesItem, location }) => (
  <>
    <Typography variant="h4">
      {salesItem.label} opslagruimte in {location.description}
    </Typography>
  </>
);

export default ProductHeader;
