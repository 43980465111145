// Checkout.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SimpleHeader from '../components/SimpleHeader';
import SimpleFooter from '../components/SimpleFooter';
import { useBooking } from '../context/booking/BookingProvider';
import { useUserDetails } from '../context/UserDetail'; // Zorg dat je de juiste import hebt

const Checkout = () => {
  const navigate = useNavigate();
  const { userDetails } = useUserDetails();
  const { bookingDetails } = useBooking();

  const [termsAccepted, setTermsAccepted] = useState(false);

  // useEffect om de navigatie te handhaven wanneer de pagina wordt geladen
  useEffect(() => {
    if (!userDetails.email || !bookingDetails.selectedLocation || !bookingDetails.selectedBox) {
      navigate('/'); // Navigeren naar de homepage
    }
  }, [userDetails, bookingDetails, navigate]);

  // Als de vereiste gegevens ontbreken, retourneer dan niets
  if (!userDetails.email || !bookingDetails.selectedLocation || !bookingDetails.selectedBox) {
    return null; // Stop de render om fouten te voorkomen
  }

  const handlePayment = () => {
    if (termsAccepted) {
      console.log('Proceeding to payment...');
      // Hier zou je de logic toevoegen om een betaling te starten en een order aan te maken
      navigate('/payment-success');
    } else {
      alert('Please accept the terms and conditions before proceeding.');
    }
  };

  // Filter eenmalige extra kosten uit de geselecteerde extra's
  const oneTimeExtras = bookingDetails.selectedExtras.filter((extra) => extra.type === 'once');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <SimpleHeader />
      <Container sx={{ py: { xs: 1, md: 4 } }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Overzicht te betalen
        </Typography>
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6">Jouw gegevens</Typography>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12}>
              {!bookingDetails.private && (
                <>
                  <Typography variant="body1">
                    <strong>Bedrijfsnaam:</strong> {userDetails.tradingName}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Kvk #:</strong> {userDetails.kvkNumber}
                  </Typography>
                </>
              )}

              <Typography variant="body1">
                <strong>Voornaam:</strong> {userDetails.firstName}
              </Typography>
              <Typography variant="body1">
                <strong>Mobiel:</strong> {userDetails.phoneNumber}
              </Typography>
              <Typography variant="body1">
                <strong>Adres:</strong>{' '}
                {`${userDetails.street} ${userDetails.houseNumber}${userDetails.houseNumberAddition ? ` ${userDetails.houseNumberAddition}` : ''
                }, ${userDetails.postalCode} ${userDetails.city}`}
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body1">
            <strong>Locatie:</strong>{' '}
            {bookingDetails.selectedLocation.description}
          </Typography>
          <Typography variant="body1">
            <strong>Box:</strong>{' '}
            {bookingDetails.selectedBox.label}
          </Typography>

          {/* Overzicht van eenmalige kosten */}
          {bookingDetails.oneTimeCosts > 0 && (
            <Box mt={4}>
              <Typography variant="h6">Eenmalige Kosten</Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Omschrijving</TableCell>
                      <TableCell align="right" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                        Aantal
                      </TableCell>
                      <TableCell align="right" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                        Prijs
                      </TableCell>
                      <TableCell align="right">Totaal</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {oneTimeExtras.map((extra, index) => (
                      <TableRow key={`${extra.key}-${index}`}>
                        <TableCell>{extra.label}</TableCell>
                        <TableCell align="right" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                          {extra.quantity}
                        </TableCell>
                        <TableCell align="right" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                          €{extra.price.toFixed(2)}
                        </TableCell>
                        <TableCell align="right">€{(extra.price * extra.quantity).toFixed(2)}</TableCell>
                      </TableRow>
                    ))}
                    {bookingDetails.oneTimeDiscount.discountValidated && (
                      <TableRow>
                        <TableCell>Korting ({bookingDetails.oneTimeDiscount.discountCode})</TableCell>
                        <TableCell align="right" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                          -
                        </TableCell>
                        <TableCell align="right" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                          -
                        </TableCell>
                        <TableCell align="right">
                          -€{bookingDetails.oneTimeDiscount.discountValue.toFixed(2)}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* Totaal overzicht */}
              <Box mt={2}>
                <Grid container>
                  <Grid item xs={6} sm={9} />
                  <Grid item xs={6} sm={3} sx={{ textAlign: 'right' }}>
                    <Typography variant="body1">
                      <strong>Totaal:</strong> €{bookingDetails.oneTimeCosts.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        </Paper>

        {/* Checkbox voor Algemene Voorwaarden */}
        <FormControlLabel
          control={
            <Checkbox
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
              color="primary"
              disableRipple
            />
          }
          label={
            <Typography variant="body2">
              Ik ga akkoord met de{' '}
              <Link
                href="https://www.swartbox.nl/wp-content/uploads/2024/08/Algemene-Voorwaarden-Self-Storage.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Algemene voorwaarden
              </Link>
            </Typography>
          }
        />

        <Button
          variant="contained"
          color="primary"
          disabled={!termsAccepted}
          onClick={handlePayment}
          sx={{ mt: 2 }}
        >
          Betalen
        </Button>
      </Container>
      <SimpleFooter />
    </Box>
  );
};

export default Checkout;
