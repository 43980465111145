import React, { useEffect, useState } from 'react';
import { Box, Typography, Container, CircularProgress, Button } from '@mui/material';
import api from '../../api';
import SimpleHeader from '../SimpleHeader';
import SimpleFooter from '../SimpleFooter';
import { useNavigate } from 'react-router-dom';

const UserProfile = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await api.get('/user', { withCredentials: true });
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user details:', error);
        navigate('/user/login'); // Redirect to login if user is not authenticated
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [navigate]);

  const handleLogout = async () => {
    try {
      await api.post('/auth/logout', {}, { withCredentials: true });
      navigate('/user/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!user) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6">User not found</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <SimpleHeader />
      <Container sx={{ flex: 1, mt: 4 }}>
        <Typography variant="h4">User Profile</Typography>
        <Typography variant="body1">Name: {user.FirstName} {user.LastName}</Typography>
        <Typography variant="body1">Email: {user.Email[0].Contact}</Typography>
        {/* Voeg meer gebruikersinformatie toe zoals gewenst */}
        <Button variant="contained" color="primary" sx={{ mt: 4 }} onClick={handleLogout}>Logout</Button>
      </Container>
      <SimpleFooter />
    </Box>
  );
};

export default UserProfile;
