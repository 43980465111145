import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Grid, CircularProgress } from '@mui/material';
import SimpleHeader from '../components/SimpleHeader';
import SimpleFooter from '../components/SimpleFooter';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import api from '../api'; // Zorg ervoor dat je API configuratie correct is
import { CustomCard, Left, Title, Content, Right } from '../components/CustomCard';

const Home = () => {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get('/locations')
      .then(response => {
        setLocations(response.data);
      })
      .catch(error => console.error('Error fetching the data:', error))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    document.title = "Kies je locatie | Swartbox"; // Stel de titel in wanneer het component wordt geladen
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <SimpleHeader />
      <Container sx={{ py: {xs: 1, md: 4} }}>
        <Box sx={{ mb: 4, textAlign: 'center' }}>
          <Typography variant="h4" component="div">
            Kies jouw locatie
          </Typography>
        </Box>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2}>
            {locations.map((location, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <CustomCard to={`/product-list/${location.code}`}>
                  <Left>
                    <LocationOnIcon />
                  </Left>
                  <Title>
                    {location.description}
                  </Title>
                  <Content>
                    {location.street}
                  </Content>
                  <Right>
                    <ChevronRightIcon />
                  </Right>
                </CustomCard>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
      <SimpleFooter />
    </Box>
  );
};

export default Home;
