export const calculateProRatedCost = (startDate, endDate, monthlyBaseCost) => {
    let totalCost = 0;
    let currentMonth = new Date(startDate.getFullYear(), startDate.getMonth(), 1);

    // Loop through each month from start to end
    while (currentMonth <= endDate) {
        const daysInMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();

        // If we're in the start month
        if (currentMonth.getMonth() === startDate.getMonth() && currentMonth.getFullYear() === startDate.getFullYear()) {
            // Calculate cost for remaining days in the start month
            const remainingDaysInMonth = daysInMonth - startDate.getDate() + 1;
            const dailyRate = monthlyBaseCost / daysInMonth;
            totalCost += dailyRate * remainingDaysInMonth;

        // If we're in the end month
        } else if (currentMonth.getMonth() === endDate.getMonth() && currentMonth.getFullYear() === endDate.getFullYear()) {
            // Calculate cost for the days up to the end date
            const daysInEndMonth = endDate.getDate();
            const dailyRate = monthlyBaseCost / daysInMonth;
            totalCost += dailyRate * daysInEndMonth;

        // For full months in between
        } else {
            totalCost += monthlyBaseCost;
        }

        // Move to the next month
        currentMonth.setMonth(currentMonth.getMonth() + 1);
    }

    return totalCost;
};

export const calculateDiscountValue = (discount, price, quantity) => {
    if (!discount || discount.amount <= 0 || !price || !quantity) return 0;

    const minQuantity = discount.min_quantity ?? 1;
  
    // Check if the quantity meets the minimum quantity requirement
    if (quantity < minQuantity) return 0;
  
    // Determine how many items the discount should apply to
    const applicableQuantity = Math.floor(quantity / minQuantity) * minQuantity;

    // Calculate discount based on the applicable quantity
    const applicableTotalPrice = price * applicableQuantity;

    return discount.type === 'percentage'
      ? (applicableTotalPrice * discount.amount) / 100
      : discount.amount * applicableQuantity;
  };

  export const calculateEndOfMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    return new Date(year, month + 1, 0); // Last day of the current month
  };

  export const applyDiscountToBookingDetails = (discount, bookingDetails) => {
    const updatedDetails = { ...bookingDetails };

    // Controleer of de korting al bestaat
    const discountAlreadyApplied =
      updatedDetails.monthlyDiscount.some((d) => d.code === discount.code) ||
      updatedDetails.oneTimeDiscount.some((d) => d.code === discount.code) ||
      updatedDetails.selectedExtras.some((extra) => extra.discount?.code === discount.code);

    if (discountAlreadyApplied) {
        return bookingDetails; // Geen wijzigingen als de korting al is toegepast
    }
  
    // Voeg de korting toe aan de juiste lijst of extra's
    if (discount.applicableTo) {
        updatedDetails.selectedExtras = updatedDetails.selectedExtras.map((extra) => {
          if (discount.applicableTo.includes(extra.id) && extra.quantity >= discount.min_quantity) {
            const applicableQuantity = Math.min(extra.quantity, discount.max_quantity || extra.quantity);
            const discountValue = discount.type === 'overwrite' 
              ? (extra.price - discount.amount) * applicableQuantity 
              : calculateDiscountValue(discount, extra.price, applicableQuantity);
    
            return {
              ...extra,
              discount: {
                code: discount.code,
                amount: discount.amount,
                type: discount.type,
                value: discountValue,
                apply_automatically: discount.apply_automatically ?? false
              },
            };
          }
          return extra;
        });
    }else if (discount.scope === "recurring") {
      const discountValue = calculateDiscountValue(discount, updatedDetails.monthlyCosts, 1);
      updatedDetails.monthlyDiscount = [
        ...updatedDetails.monthlyDiscount,
        {
          code: discount.code,
          amount: discount.amount,
          type: discount.type,
          value: discountValue,
          apply_automatically: discount.apply_automatically ?? false
        },
      ];
    } else if (discount.scope === "once") {
      const discountValue = calculateDiscountValue(discount, updatedDetails.oneTimeCosts, 1);
      updatedDetails.oneTimeDiscount = [
        ...updatedDetails.oneTimeDiscount,
        {
          code: discount.code,
          amount: discount.amount,
          type: discount.type,
          value: discountValue,
          apply_automatically: discount.apply_automatically ?? false
        },
      ];
    } 
  
    return updatedDetails;
  };