import React, { useState, useRef, useEffect } from 'react';
import { Box, Container, Button, Typography } from '@mui/material';
import SimpleHeader from '../SimpleHeader';
import SimpleFooter from '../SimpleFooter';

const Scan = () => {
  const [cameraOpen, setCameraOpen] = useState(false);
  const [photo, setPhoto] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    let videoElement = videoRef.current;
    if (cameraOpen) {
      startCamera();
    }
    return () => {
      if (videoElement && videoElement.srcObject) {
        videoElement.srcObject.getTracks().forEach(track => track.stop());
      }
    };
  }, [cameraOpen]);

  const startCamera = async () => {
    try {
      const constraints = {
        video: {
          facingMode: 'environment',
          width: { ideal: 1920 }, // Hoge resolutie breedte
          height: { ideal: 1080 }, // Hoge resolutie hoogte
        },
      };

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  const takePhoto = () => {
    if (videoRef.current && videoRef.current.readyState === 4) {
      const videoWidth = videoRef.current.videoWidth;
      const videoHeight = videoRef.current.videoHeight;
    
      // Bepaal de factor op basis van de video- en schermafmetingen
      const screenRatio = videoWidth / window.innerWidth;
  
      // Pas de hoogte van de overlay aan op basis van de verhouding
      const overlayHeight = (window.innerHeight * screenRatio) * 0.30; // Verhoogde hoogte naar 30%
      const overlayWidth = videoWidth * 0.8;
      const overlayX = (videoWidth - overlayWidth) / 2;
      const overlayY = (videoHeight - overlayHeight) / 2;
  
      // Pas de canvas aan om alleen het overlaygebied te capturen
      const canvas = canvasRef.current;
      canvas.width = overlayWidth;
      canvas.height = overlayHeight;
  
      const context = canvas.getContext('2d');
      context.drawImage(
        videoRef.current,
        overlayX,
        overlayY,
        overlayWidth,
        overlayHeight,
        0,
        0,
        overlayWidth,
        overlayHeight
      );
  
      const dataUrl = canvas.toDataURL('image/png', 1.0);
  
      setPhoto(dataUrl);
      if (videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach(track => track.stop());
      }
      setCameraOpen(false);
    }
  };
 

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <SimpleHeader />
      <Container sx={{ flex: 1, mt: 4 }}>
        <Typography variant="h4" sx={{ mb: 4 }}>Scan ID</Typography>

        {!cameraOpen && (
          <Button variant="contained" color="secondary" onClick={() => setCameraOpen(true)} sx={{ mt: 2 }}>
            Open Camera
          </Button>
        )}
        {cameraOpen && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              backgroundColor: 'black',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1000,
            }}
          >
            <video ref={videoRef} style={{ width: '100%', height: '100%' }} playsInline />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                pointerEvents: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '80%',
                  height: '30%',
                  border: '2px dashed white',
                  borderRadius: '10px',
                  backgroundColor: 'transparent',
                  zIndex: 2,
                  pointerEvents: 'none',
                }}
              />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                bottom: '10%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 3,
              }}
            >
              <Button 
                variant="text"
                color="primary"
                onClick={() => setCameraOpen(false)}
                sx={{
                  position: 'absolute',
                  left: 20,
                  color: 'white',
                }}
              >
                Annuleer
              </Button>
              <Button
                variant="contained"
                onClick={takePhoto}
                sx={{
                  width: 60,
                  height: 60,
                  borderRadius: '50%',
                  backgroundColor: 'white',
                  color: 'black',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: '0 0 10px rgba(0,0,0,0.5)',
                }}
              >
                <span />
              </Button>
            </Box>
          </Box>
        )}
        {photo && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6">Photo taken:</Typography>
            <img src={photo} alt="ID" style={{ width: '100%' }} />
          </Box>
        )}
        <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
      </Container>
      <SimpleFooter />
    </Box>
  );
};

export default Scan;
