import React, { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Box,
  Button,
  Typography,
  Container,
  Grid,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import '../../styles/PhoneInput.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import SimpleHeader from '../SimpleHeader';
import SimpleFooter from '../SimpleFooter';
import api from '../../api';

import { useUserDetails } from '../../context/UserDetail';
import { useBooking } from '../../context/booking/BookingProvider';
import ProductPriceDetail from '../product/ProductPriceDetail';

// Function to validate KvK number through API
const validateKvKNumber = async (kvkNumber) => {
  try {
    const response = await api.get(`/kvk/validate-kvk/${kvkNumber}`);
    if (response.status === 200) {
      return response.data; // Return the data if the request is successful
    }
    return null;
  } catch (error) {
    console.error('KvK validation failed:', error);
    return null;
  }
};

const validateAddress = async (postalCode, houseNumber, addition) => {
  if (!postalCode || !houseNumber) return null;

  try {
    const response = await api.post('/address/get-address', {
      postalCode,
      houseNumber,
      addition,
    });
    if (response.status === 200) {
      return response.data; // Return the data if the request is successful
    }
    return null;
  } catch (error) {
    console.error('Address validation failed:', error);
    return null;
  }
};

// Validation schema with Yup
const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, 'Voornaam moet minimaal 2 letters hebben')
    .required('Voornaam is verplicht'),
  phoneNumber: yup
    .string()
    .test('is-valid', 'Voer een geldig mobiel telefoonnummer in', (value) =>
      value ? isValidPhoneNumber(value) : true
    )
    .required('Telefoonnummer is verplicht'),
  postalCode: yup
    .string()
    .required('Postcode is verplicht')
    .matches(/^[0-9]{4}[A-Z]{2}$/, 'Voer een geldige postcode in het formaat 1234AB in'),
  houseNumber: yup
    .string()
    .required('Huisnummer is verplicht')
    .matches(/^[0-9]/, 'Alleen cijfers zijn toegestaan'),
  street: yup.string(),
  city: yup.string(),
  additions: yup.array().of(yup.string()).default([]),
  houseNumberAddition: yup.string().when('additions', {
    is: (additions) => additions && additions.length > 0,
    then: (schema) => schema.required('Selecteer een huisnummertoevoeging'),
    otherwise: (schema) => schema.nullable(),
  }),
  address: yup.string().when(['postalCode', 'houseNumber'], {
    is: (postalCode, houseNumber) =>
      postalCode && /^[0-9]{4}[A-Z]{2}$/.test(postalCode) && /^[0-9]/.test(houseNumber),
    then: (schema) =>
      schema.test(
        'validate-address',
        'Ongeldig adres. Controleer de postcode en het huisnummer.',
        async function () {
          const { postalCode, houseNumber, houseNumberAddition } = this.parent;
          const { context } = this.options;

          if (
            postalCode === context.yupAddress.current.postalCode &&
            houseNumber === context.yupAddress.current.houseNumber
          ) {
            return context.yupAddress.current.status;
          }

          context.yupAddress.current.postalCode = postalCode;
          context.yupAddress.current.houseNumber = houseNumber;
          context.yupAddress.current.houseNumberAddition = houseNumberAddition;

          const addressData = await validateAddress(postalCode, houseNumber, houseNumberAddition);
          if (addressData) {
            context.yupAddress.current.status = true;
            context.setDataFromAddress(addressData);
            return true;
          }
          context.yupAddress.current.status = false;
          context.setDataFromAddress();
          return false
        }
      ),
      otherwise: (schema) => 
        schema.test('clear-address-data', function () {
          const { context } = this.options;
          context.setDataFromAddress();
          return true;
        }).nullable()
  }),
  kvkNumber: yup.string().when('$isBusiness', {
    is: true,
    then: (schema) =>
      schema
        .required('KvK-nummer is verplicht voor zakelijke klanten')
        .test('is-valid-format', 'KvK-nummer bestaat uit 8 cijfers', function (value) {
          const { context } = this.options;
  
          if (!/^\d{8}$/.test(value)) {
            context.setDataFromKvK();
            return false;
          }
  
          return true;
        })
        .test(
          'kvk-api-validation',
          'Ongeldig KvK-nummer. Controleer het alstublieft opnieuw.',
          async function (value) {
            const { context } = this.options;
            if (!value || !/^\d{8}$/.test(value)) {
              return false;
            }
            if (value === context.yupKvk.current.lastValidatedKvKNumber) {
              return context.yupKvk.current.lastValidationKvkResult;
            }
            context.yupKvk.current.lastValidatedKvKNumber = value;
            const kvkData = await validateKvKNumber(value);
            if (kvkData) {
              context.setDataFromKvK(kvkData);
              context.yupKvk.current.lastValidationKvkResult = true;
              return true;
            }
            context.yupKvk.current.lastValidationKvkResult = false;
            context.setDataFromKvK();
            return false;
          }
        ),
    otherwise: (schema) => schema.nullable(),
  }),
});


const Register = () => {
  const navigate = useNavigate();
  const [tradingNames, setTradingNames] = useState([]);
  const yupKvk = useRef({
    lastValidatedKvKNumber: '',
    lastValidationKvkResult: false
  });
  const yupAddress = useRef({
    postalCode: '',
    houseNumber: '',
    addition: '',
    status: false
  });

  const { userDetails, updateUserDetails } = useUserDetails();
  const { bookingDetails, togglePrivateStatus } = useBooking();
  const { private: isPrivate } = bookingDetails;

  useEffect(() => {
    if (!bookingDetails.selectedLocation || !bookingDetails.selectedBox) {
      navigate('/'); // Navigeren naar de homepage
    }
    if (!userDetails.email) {
      navigate('/user/login');
    }
  }, [userDetails, bookingDetails, navigate]);

  const [additions, setAdditions] = useState([]);

  const setDataFromKvK = (kvkData) => {
    if (kvkData) {
      const visitAddress = kvkData._embedded.hoofdvestiging.adressen.find(
        (addr) => addr.type === 'bezoekadres'
      );
  
      if (visitAddress) {
        setValue('postalCode', visitAddress.postcode || '');
        setValue('houseNumber', visitAddress.huisnummer || '');
        setValue('street', visitAddress.straatnaam || '');
        setValue('city', visitAddress.plaats || '');
      } else {
        // Clear address fields if no visit address is found
        setValue('postalCode', '');
        setValue('houseNumber', '');
        setValue('street', '');
        setValue('city', '');
      }
  
      const tradingNames = kvkData.handelsnamen.map((h) => h.naam);
      setTradingNames(tradingNames);
    } else {
      // Clear address fields and trading names if kvkData is invalid
      setValue('postalCode', '');
      setValue('houseNumber', '');
      setValue('street', '');
      setValue('city', '');
      setTradingNames([]);
    }
  };

  const setDataFromAddress = (addressData) => {
    if(addressData){
      setAdditions(addressData.additions || []);
      setValue('street', addressData.street || '');
      setValue('city', addressData.city || '');
      setValue('additions', addressData.additions || []);
      setValue('houseNumberAddition', '');
    }else{
      setAdditions([]);
      setValue('street', '');
      setValue('city', '');
      setValue('additions', []);
      setValue('houseNumberAddition', '');
    }
  }
  

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setValue,
    trigger
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    context: { isBusiness: !isPrivate, setDataFromKvK, yupKvk, setDataFromAddress, yupAddress },
    defaultValues: {
      additions: [],
      houseNumberAddition: '',
    },
  });

  useEffect(() => {
    if (!isPrivate && userDetails.kvkNumber) {
      trigger('kvkNumber');
    }
  }, [isPrivate, userDetails.kvkNumber, setValue, trigger]);

  const handleToggleChange = (event, newValue) => {
    if (newValue !== null) {
      togglePrivateStatus();
    }
  };

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedTrigger = debounce(() => {
    trigger('address');
  }, 500);
  
  const handlePostalCodeChange = (value) => {
    setValue('postalCode', value.toUpperCase().replace(/\s/g, ''));
    debouncedTrigger();
  };

  const handleHouseNumberChange = (value) => {
    setValue('houseNumber', value);
    debouncedTrigger();
  }

  const onSubmit = async (data) => {
    // Sla de gebruikersgegevens op in de context
    updateUserDetails({
      firstName: data.firstName,
      phoneNumber: data.phoneNumber,
      street: data.street,
      houseNumber: data.houseNumber,
      houseNumberAddition: data.houseNumberAddition,
      postalCode: data.postalCode,
      city: data.city,
      kvkNumber: data.kvkNumber || '',
      tradingName: data.tradingName
    });
  
    // Navigeer naar de checkout pagina
    navigate('/checkout');
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', pb: { xs: '95px', md: 0 } }}>
      <SimpleHeader />
      <Container sx={{ mt: 4, flex: 1 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h4">Registreer</Typography>
          <Typography variant="body1">
            Vul hieronder je gegevens in om je opslagruimte te huren
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {userDetails.email}{' '}
            <EditIcon
              sx={{ cursor: 'pointer' }}
              onClick={() => navigate('/user/login')}
            />
          </Typography>

          <ToggleButtonGroup
            value={isPrivate ? 'prive' : 'zakelijk'}
            exclusive
            onChange={handleToggleChange}
            aria-label="huur type"
            sx={{ mb: 3 }}
          >
            <ToggleButton value="prive" aria-label="prive">
              Prive
            </ToggleButton>
            <ToggleButton value="zakelijk" aria-label="zakelijk">
              Zakelijk
            </ToggleButton>
          </ToggleButtonGroup>

          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                {!isPrivate && (
                  <>
                    <Grid item xs={12}>
                      <Controller
                        name="kvkNumber"
                        control={control}
                        defaultValue={userDetails.kvkNumber || ''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="KvK-nummer"
                            error={!!errors.kvkNumber}
                            helperText={errors.kvkNumber ? errors.kvkNumber.message : ''}
                            fullWidth
                            sx={{
                              mb: 2,
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor:
                                    field.value && !errors.kvkNumber ? '#4caf50' : '',
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    {tradingNames.length > 0 && (
                      <Grid item xs={12}>
                        <Controller
                          name="tradingName"
                          control={control}
                          defaultValue={tradingNames[0]}
                          render={({ field }) => (
                            <FormControl
                              fullWidth
                              sx={{
                                mb: 2,
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor: !errors.tradingName && field.value ? '#4caf50' : '', // Apply green border if valid
                                  },
                                },
                              }}
                              error={!!errors.tradingName} // This will apply error styling if the field is invalid
                            >
                              <InputLabel>Handelsnaam</InputLabel>
                              <Select
                                {...field}
                                label="Handelsnaam"
                                disabled={tradingNames.length === 1}
                              >
                                {tradingNames.map((name, index) => (
                                  <MenuItem key={index} value={name}>
                                    {name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Grid>
                    )}
                  </>
                )}

                <Grid item xs={12}>
                  <Typography variant="h6" sx={{mb: 2}}>Persoonsgegevens</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="firstName"
                    control={control}
                    defaultValue={userDetails.firstName || ''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Voornaam"
                        error={!!errors.firstName}
                        helperText={
                          errors.firstName ? errors.firstName.message : ''
                        }
                        fullWidth
                        sx={{
                          mb: 2,
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor:
                                field.value && !errors.firstName 
                                  ? '#4caf50' : '',
                            },
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    defaultValue={userDetails.phoneNumber || ''}
                    render={({ field }) => (
                      <PhoneInput
                        {...field}
                        defaultCountry="NL"
                        placeholder="Mobielnummer"
                        className={
                          errors.phoneNumber
                            ? 'PhoneInputError'
                            : field.value && !errors.phoneNumber
                              ? 'PhoneInputValid' : ''
                        }
                      />
                    )}
                  />
                  {errors.phoneNumber && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ ml: '54px' }}
                    >
                      {errors.phoneNumber.message}
                    </Typography>
                  )}
                </Grid>
                {isPrivate && (
                  <>
                    <Grid item xs={4} sm={4}>
                      <Controller
                        name="postalCode"
                        control={control}
                        defaultValue={userDetails.postalCode || ''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Postcode"
                            onChange={(e) => handlePostalCodeChange(e.target.value)}
                            error={!!errors.postalCode || !!errors.address}  // Check for postalCode or address errors
                            helperText={
                              errors.postalCode ? errors.postalCode.message : errors.address ? errors.address.message : ''
                            }  // Display either postalCode error or address error
                            fullWidth
                            sx={{
                              mb: 2,
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor:
                                    field.value && !errors.postalCode && !errors.address
                                      ? '#4caf50' : '',
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <Controller
                        name="houseNumber"
                        control={control}
                        defaultValue={userDetails.houseNumber || ''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Huisnummer"
                            onChange={(e) => handleHouseNumberChange(e.target.value)}
                            error={!!errors.houseNumber || !!errors.address}
                            helperText={
                              errors.houseNumber ? errors.houseNumber.message : ''
                            }
                            fullWidth
                            sx={{
                              mb: 2,
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor:
                                    field.value && !errors.houseNumber
                                      ? '#4caf50' : '',
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    {additions.length > 0 && (
                      <Grid item xs={4} sm={4}>
                        <Controller
                          name="houseNumberAddition"
                          control={control}
                          defaultValue={userDetails.houseNumberAddition || ''}
                          render={({ field }) => (
                            <FormControl
                              fullWidth
                              sx={{
                                mb: 2,
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor:
                                      field.value && !errors.houseNumberAddition
                                        ? '#4caf50' : '',
                                  },
                                },
                              }}
                              error={!!errors.houseNumberAddition}
                            >
                              <InputLabel>Toevoeging</InputLabel>
                              <Select
                                {...field}
                                label="Toevoeging"
                                MenuProps={{ disablePortal: true }}
                                onChange={(e) => {
                                  setValue('houseNumberAddition', e.target.value);
                                  field.onChange(e);
                                }}
                                value={field.value || ''} // Default to an empty string if value is null
                              >
                                {additions.map((addition, index) => (
                                  <MenuItem key={index} value={addition}>
                                    {addition}
                                  </MenuItem>
                                ))}
                              </Select>
                              {errors.houseNumberAddition && (
                                <Typography variant="caption" color="error">
                                  {errors.houseNumberAddition.message}
                                </Typography>
                              )}
                            </FormControl>
                          )}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="street"
                        control={control}
                        defaultValue={userDetails.street || ''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Straat"
                            disabled
                            fullWidth
                            sx={{
                              mb: 2,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="city"
                        control={control}
                        defaultValue={userDetails.city || ''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Plaats"
                            disabled
                            fullWidth
                            sx={{
                              mb: 2,
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
              </Grid>  
            </Grid>
            <Grid item xs={12} md={4}>
              <ProductPriceDetail>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  fullWidth
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <CircularProgress size={24} /> : 'Volgende'}
                </Button>
              </ProductPriceDetail>
            </Grid>
          </Grid>
        </form>
      </Container>
      <SimpleFooter sx={{ display: { xs: 'none', md: 'block' } }} />
    </Box>
  );
};

export default Register;
